import axios from '@/features/dynamic-checkout/api'
import i18n from '@/application/i18n/i18n'
import type {
  BaseCheckoutRule,
  CheckoutRule,
} from '@/features/dynamic-checkout-rules/types/checkout-rules.types'
import { normalizeError } from '@/common/utils/errors/normalize-error'
import type { AxiosResponse } from 'axios'
import type { CommonPossibilities } from '@/features/rules/types/rules.types'

export async function fetchCheckoutRules(): Promise<CheckoutRule[]> {
  try {
    const response = await axios.get('/rules')
    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw normalizeError(error, i18n.t('checkout_rules.error.fetch_rules'))
  }
}

export async function createCheckoutRule(
  checkoutRule: BaseCheckoutRule,
): Promise<CheckoutRule> {
  try {
    const response = await axios.post(`/rules`, checkoutRule)
    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw normalizeError(error, i18n.t('checkout_rules.error.create_rule'))
  }
}

export async function deleteCheckoutRule(ruleId: number): Promise<void> {
  try {
    await axios.delete(`/rules/${ruleId}`)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw normalizeError(error, i18n.t('checkout_rules.error.delete_rule'))
  }
}

export async function updateCheckoutRule(
  checkoutRule: CheckoutRule,
): Promise<CheckoutRule> {
  try {
    const response = await axios.put(`/rules/${checkoutRule.id}`, checkoutRule)
    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw normalizeError(error, i18n.t('checkout_rules.error.update_rule'))
  }
}

export async function fetchCheckoutRule(ruleId: number): Promise<CheckoutRule> {
  try {
    const response = await axios.get(`/rules/${ruleId}`)
    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw normalizeError(error, i18n.t('checkout_rules.error.load_rule'))
  }
}

export async function fetchCheckoutRulePossibilities(): Promise<
  AxiosResponse<CommonPossibilities>
> {
  try {
    return axios.get('/rules/possibilities')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw normalizeError(
      error,
      i18n.t('checkout_rules.error.load_possibilities'),
    )
  }
}
