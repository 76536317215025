import store from '@/common/stores/store'
import { ANALYTICS_SHIPPING_ROUTE } from '@/features/analytics/routes'
import { RETURNS_ROUTE } from '@/features/returns/routes'
import { PICKUPS_ROUTE } from '@/features/pickups/routes'
import { SETTINGS_BRANDS_ROUTE } from '@/features/brands/routes'
import { SETTINGS_FINANCIAL_ROUTE } from '@/features/financial/routes'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { SHIPPING_CREATED_LABELS_ROUTE } from '@/features/shipment-tabs/routes'
import type { UserPermissions } from '@/types/models'

export const FALLBACK_ROUTE = 'fallback-route'

/**
 * When a user does not have access to a certain route we need to fallback to a
 * different one. We need to pick a fallback route the user does have access to,
 * hence, we need to check permissions.
 *
 */
export function getFallbackRedirect(permissions: UserPermissions): { name: string } {
  if (permissions.process_orders) {
    return { name: SHIPPING_INCOMING_ORDERS_ROUTE }
  } else if (permissions.manage_shipping) {
    return { name: SHIPPING_CREATED_LABELS_ROUTE }
  } else if (permissions.manage_invoices) {
    return { name: SETTINGS_FINANCIAL_ROUTE }
  } else if (permissions.manage_brands) {
    return { name: SETTINGS_BRANDS_ROUTE }
  } else if (permissions.view_analytics) {
    return { name: ANALYTICS_SHIPPING_ROUTE }
  } else if (permissions.manage_pickups) {
    return { name: PICKUPS_ROUTE }
  } else if (permissions.manage_returns) {
    return { name: RETURNS_ROUTE }
  } else {
    // This case should normally not happen -- given the role/permission definitions,
    // one of the above should really match.
    return { name: SHIPPING_INCOMING_ORDERS_ROUTE }
  }
}

export default {
  name: FALLBACK_ROUTE,
  path: '/:pathMatch(.*)*',
  redirect: () => {
    return getFallbackRedirect(store.getters.user?.permissions ?? {})
  },
  meta: {
    pageTitle: 'Redirecting ...',
    permissions: [],
  },
}
