export const SYSTEMS = {
  WINDOWS: 'Windows',
  MACOS: 'macOS',
  LINUX: 'Linux',
  UNKNOWN: 'Unknown',
}

export const BROWSERS = {
  OPERA: 'Opera',
  FIREFOX: 'Firefox',
  SAFARI: 'Safari',
  CHROME: 'Chrome',
  IE: 'Internet Explorer',
  EDGE: 'Edge',
  UNKNOWN: 'Unknown',
}

export default {
  getBrowser() {
    // Chrome 1+
    if ((window.chrome && window.chrome.webstore) || window.navigator.userAgent.includes('Chrome')) {
      return BROWSERS.CHROME
    }

    // Firefox 1.0+
    if (window.InstallTrigger !== undefined) {
      return BROWSERS.FIREFOX
    }

    // Internet Explorer 6-11
    if (/* @cc_on!@ */ false || document.documentMode) {
      return BROWSERS.IE
    }

    // Edge 20+
    if (window.StyleMedia) {
      return BROWSERS.EDGE
    }

    // Safari 3.0+ "[object HTMLElementConstructor]"
    if (
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]'
      })(!window.safari || window.safari.pushNotification)
    ) {
      return BROWSERS.SAFARI
    }

    // Opera 8.0+
    if ((window.opr && window.opr.addons) || window.opera || window.navigator.userAgent.includes(' OPR/')) {
      return BROWSERS.OPERA
    }

    return BROWSERS.UNKNOWN
  },

  getSystem() {
    const platform = window.navigator.platform

    if (platform.includes('Mac')) {
      return SYSTEMS.MACOS
    } else if (platform.includes('Win')) {
      return SYSTEMS.WINDOWS
    } else if (platform.includes('Linux')) {
      return SYSTEMS.Linux
    } else {
      return SYSTEMS.UNKNOWN
    }
  },
}
