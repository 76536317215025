import axios from '@/common/utils/axios'

export default {
  async getReturnDefaults() {
    const response = await axios.get('/xhr/return-defaults')
    return response.data
  },

  async updateReturnDefaults(returnFormShippingMethod: number | string, returnOrderShippingMethod: number | string) {
    await axios.patch('/xhr/return-defaults', {
      return_form_shipping_method: returnFormShippingMethod,
      return_order_shipping_method: returnOrderShippingMethod,
    })
  },
}
