/** @type {import('node_modules/vue-router/types/index').RouteConfig[]} */
import i18n from '@/application/i18n/i18n'
import integrationsRouteGuard from '@/features/integrations/guards/integrations-route-guard.js'
import { type RouteRecordRaw, type RouteLocationNormalized, RouterView } from 'vue-router'

export const SETTINGS_INTEGRATIONS_ROUTE = 'integrations'
export const SETTINGS_INTEGRATIONS_MANAGE_ROUTE = 'integrations.manage'
export const SETTINGS_INTEGRATIONS_ADD_ROUTE = 'integrations.add'
export const SETTINGS_INTEGRATIONS_SETTINGS_ROUTE = 'integrations.settings'
export const SETTINGS_INTEGRATIONS_LOGS_ROUTE = 'integrations.logs'

export default {
  name: SETTINGS_INTEGRATIONS_ROUTE,
  path: 'integrations',
  redirect: { name: SETTINGS_INTEGRATIONS_MANAGE_ROUTE },
  component: RouterView,
  children: [
    {
      name: SETTINGS_INTEGRATIONS_MANAGE_ROUTE,
      path: 'manage',
      component: () => import('@/features/integrations/pages/IntegrationSettingsPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Integrations'),
        permissions: ['manage_integrations'],
        parent: 'settings-integrations',
      },
    },
    {
      name: SETTINGS_INTEGRATIONS_LOGS_ROUTE,
      path: 'logs/:id?',
      component: () => import('@/features/integrations/pages/IntegrationLogPage.vue'),
      props: (route: RouteLocationNormalized) => ({
        id: Number.parseInt(route.params.id as string) || undefined,
      }),
      meta: {
        pageTitle: () => i18n.t('Connection issue log'),
        permissions: ['manage_integrations'],
        parent: 'settings-integrations',
      },
    },
    {
      name: SETTINGS_INTEGRATIONS_ADD_ROUTE,
      path: ':systemCode/add',
      component: () => import('@/features/integrations/pages/IntegrationFormPage.vue'),
      beforeEnter: integrationsRouteGuard,
      props: (route: RouteLocationNormalized) => ({
        systemCode: route.params.systemCode,
      }),
      meta: {
        pageTitle: () => i18n.t('Integration configuration'),
        permissions: ['manage_integrations'],
        parent: 'settings-integrations',
      },
    },
    {
      name: SETTINGS_INTEGRATIONS_SETTINGS_ROUTE,
      path: ':systemCode/:id',
      component: () => import('@/features/integrations/pages/IntegrationFormPage.vue'),
      props: (route: RouteLocationNormalized) => ({
        id: Number.parseInt(route.params.id as string),
        systemCode: route.params.systemCode,
      }),
      meta: {
        pageTitle: () => i18n.t('Integration configuration'),
        permissions: ['manage_integrations'],
        parent: 'settings-integrations',
      },
    },
  ],
} satisfies RouteRecordRaw
