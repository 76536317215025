import * as Sentry from '@sentry/vue'

// For historical reasons and to not break the current behavior, `ngStorage-` prefix is kept
export const STORAGE_PREFIX = 'ngStorage-'

const getSize = data => String(data).length

/**
 * Sends errors to sentry along with the space used in localstorage by key to monitor errors thrown when
 * modifying the localstorage.
 *
 * @see https://sendcloud.atlassian.net/browse/SC-11757
 * @param {any} error
 */
const captureException = (error) => {
  const localStorageSizeByKey = Object.keys(localStorage).reduce(
    (acc, key) => ({
      ...acc,
      [key]: getSize(localStorage[key]),
    }),
    {},
  )
  const localStorageSizeTotal = getSize(Object.values(localStorage).join(''))
  Sentry.captureException(error, {
    extra: {
      localStorageSizeTotal,
      localStorageSizeByKey,
    },
  })
}

/**
 * @param {number} userId
 * @returns {Object}
 */
export function getUserStorage(userId) {
  try {
    const storagePayload = localStorage.getItem(`${STORAGE_PREFIX}${userId}`) || '{}'
    return JSON.parse(storagePayload)
  } catch {}
}

/**
 * @param {number} userId
 * @param {*} data
 * @private
 */
function saveUserStorage(userId, data) {
  const storageKey = `${STORAGE_PREFIX}${userId}`
  const storagePayload = JSON.stringify(data)

  try {
    localStorage.setItem(storageKey, storagePayload)
  } catch (error) {
    captureException(error)
  }
}

/**
 * @param {string} storageKey
 * @param {*} data
 */
export function setSessionStorageItem(storageKey, data) {
  try {
    if (data !== undefined) {
      sessionStorage.setItem(storageKey, JSON.stringify(data))
    }
  } catch (error) {
    captureException(error)
  }
}

/**
 * @param {string} storageKey
 */
export function removeSessionStorageItem(storageKey) {
  try {
    sessionStorage.removeItem(storageKey)
  } catch {}
}

/**
 * @param {string} storageKey
 * @param {*} defaultValue
 */
export function getSessionStorageItem(storageKey, defaultValue) {
  try {
    const storagePayLoad = sessionStorage.getItem(storageKey)
    return (storagePayLoad && JSON.parse(storagePayLoad)) || defaultValue
  } catch {}
}

/**
 * @param {number} userId
 * @param {string} storageKey
 * @param {*} value
 */
export function setVariable(userId, storageKey, value) {
  const storage = getUserStorage(userId)

  if (value !== undefined) {
    storage[storageKey] = value
  }

  saveUserStorage(userId, storage)
}

/**
 * @param {number} userId
 * @param {string} storageKey
 */
export function removeVariable(userId, storageKey) {
  const storage = getUserStorage(userId)
  delete storage[storageKey]
  saveUserStorage(userId, storage)
}

/**
 * @param {number} userId
 * @param {string} storageKey
 * @param {*} defaultValue
 * @returns {*}
 */
export function getVariable(userId, storageKey, defaultValue = false) {
  const storage = getUserStorage(userId)
  return storage[storageKey] !== undefined ? storage[storageKey] : defaultValue
}

/**
 * @param {number} userId
 * @param {string} storageKey
 * @returns {boolean}
 */
export function hasVariable(userId, storageKey) {
  const storage = getUserStorage(userId)
  return Object.prototype.hasOwnProperty.call(storage, storageKey)
}

// We do not remove these keys from local storage when a user signs in with another account.
export const PUBLIC_STORAGE_KEYS = {
  IS_PACKGO_ORDER_DETAILS_PANEL_OPEN: 'isPackGoOrderDetailsPanelOpen',
  NOT_FIRST_TIME_PACKGO_SINGLE_SCAN_ACTIVATION: 'notFirstTimePackGoSingleScanActivation',
  PRINT_OPTIONS: 'UserPrintOptions',
}

export const LOCAL_STORAGE_KEYS = {
  ...PUBLIC_STORAGE_KEYS,
  BOL_AUTH_STATE: 'bolAuthState',
  IOV_SELECTED_SORTING_SLUG: 'iovSelectedSortingSlug',
  UPS_AUTH_STATE: 'upsAuthState',
  CANCELLED_FILTERS: 'cancelledFilterParams',
  CREATED_LABELS_DEFAULT_ACTION_ID: 'createdLabelsDefaultActionId',
  CREATED_LABELS_FILTERS: 'createdLabelsFilterParams',
  CREATED_LABELS_ORDERING_OPTION_ID: 'createdLabelsOrderingOptionId',
  DO_NOT_SHOW_DEUTSCHE_POST_INTERNATIONAL_MESSAGE: 'doNotShowDhlGlobalMailMessage',
  HERMES_AUTH_STATE: 'HERMES_AUTH_STATE',
  INCOMING_ORDERS_COLUMNS: 'INCOMING_ORDERS_COLUMNS',
  INCOMING_ORDERS_DEFAULT_ACTION_ID: 'scOrdersDefaultActionID',
  INCOMING_ORDERS_FILTERS: 'scFilterParams',
  INCOMING_ORDERS_LAST_INTEGRATION_ID: 'scIncomingOrders:lastIntegration',
  INCOMING_ORDERS_SELECTED_ACTION_IDS: 'incomingOrdersSelectedActionIds',
  INTEGRATIONS_FILTERS: 'integrationsFilters',
  LAST_SELECTED_BRAND_ID: 'lastSelectedBrandID',
  NOT_PROCESSED_DEFAULT_ACTION_ID: 'notProcessedDefaultActionId',
  NOTIFY_LAST_USED_BRAND_ID: 'notifyLastUsedBrand',
  PACKGO_CUSTOMS_COPIES_SETTINGS_TRACKED: 'packgoCustomsCopiesSettingTracked',
  PACKGO_VUE_ACTIVE: 'packGoVueActive',
  PARCEL_MONITOR_OPEN: 'parcelMonitorOpen',
  RETURN_COLUMNS: 'RETURN_COLUMNS',
  RETURN_FILTERS: 'returnFilters',
  RETURN_TABS_FILTERS: 'returnTabsFilters',
  RETURNS_ITEMS_PER_PAGE: 'returnsItemsPerPage',
  RETURNS_LIST_SELECTED_ACTION_ID: 'returnsListSelectedActionId',
  RETURNS_SEND_LABEL_TO_CUSTOMER: 'returnsSendLabelToCustomer',
  RETURNS_PRINT_LABEL: 'returnsPrintLabel',
  SEEN_CANCELLATION_TOAST: 'scParcels:seenCancellationToast',
  SENDER_ADDRESS_ID: 'senderAddressId',
  SHIPPED_FILTERS: 'shippedFilterParams',
  SUPPORT_TICKET_DRAFTS: 'supportTicketDrafts',
  SUPPORT_PREFILL_TICKET_CONTENT: 'supportPrefillTicketContent',
  TRACKING_PAGE: 'trackingPage',
}

export const SESSION_STORAGE_KEYS = {}
