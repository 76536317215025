import { RETURNS_SET_DEFAULTS } from '@/features/return-defaults/stores/mutation.types.js'

import { RETURNS_FETCH_DEFAULTS } from '@/features/return-defaults/stores/action.types.js'

import ReturnDefaultsApi from '@/features/return-defaults/api/return-defaults.api'

/**
 *
 * @type {import('@/types/returns').ReturnsState}
 */
const initialState = {
  defaults: null,
}

/**
 * @type {import('@/types/returns').ReturnsGetters}
 */
const getters = {
  returnDefaults: state => state.defaults,
}

const actions = {
  async [RETURNS_FETCH_DEFAULTS]({ state, commit }) {
    if (state.defaults === null) {
      const defaults = await ReturnDefaultsApi.getReturnDefaults()
      commit(RETURNS_SET_DEFAULTS, defaults)
    }
  },
}

const mutations = {
  /**
   * @param {import('@/types/returns').ReturnsState} state
   * @param {import('@/types/returns').ReturnDefaults} defaults
   */
  [RETURNS_SET_DEFAULTS](state, defaults) {
    state.defaults = defaults
  },
}

export default {
  state: () => initialState,
  getters,
  actions,
  mutations,
}
