import i18n from '@/application/i18n/i18n'
import { isPackGoFeatureEnabled } from '@/features/packgo/utils/utilities'
import ToastService from '@/common/services/toast.service'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import type { NavigationGuardNext } from 'vue-router'

export async function packGoRouteGuard(_to: unknown, _from: unknown, next: NavigationGuardNext) {
  if (!isPackGoFeatureEnabled()) {
    ToastService.error(
      i18n.t(
        'Pack & Go is not available in your current plan. See our plans page for more information on which features are available with your current subscription.',
      ),
      {
        autoDismiss: false,
      },
    )
    return next({ name: SHIPPING_INCOMING_ORDERS_ROUTE })
  }
  next()
}
