import type { RouteLocation } from 'vue-router'

import Segment from '@/common/utils/tracking/segment'
import Sift from '@/common/utils/tracking/sift'

function callTrackPageTransition(to: RouteLocation, from: RouteLocation) {
  Sift.trackPageView()

  const trackingData = {
    title: to.meta.pageTitle(),
    referrer: from.fullPath,
  }

  Segment.page(trackingData)
}

export default callTrackPageTransition
