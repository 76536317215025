import i18n from '@/application/i18n/i18n'
import { getHelpCenterUrl } from '@/common/services/help-center-url.service'
import { useNewNavigation } from '@/common/utils/get-navigation-preference'
import type { UserPermissions } from '@/types/models'
import type { RouteButton } from '@/common/types/route.types'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import type { RouteRecordRaw } from 'vue-router'
import { betaGuard } from '@/application/routes/guards/beta-guard'
import { BETA_FEATURE_WHATSAPP } from '@/features/tracking-messages/beta-features'

export const SETTINGS_NOTIFY_TRACKING_ROUTE = 'notify'
export const SETTINGS_NOTIFY_TRACKING_MESSAGES_ROUTE = 'notify.tracking-messages'
export const SETTINGS_NOTIFY_TRACKING_MESSAGES_EMAIL_ROUTE = 'notify.tracking-messages.email'
export const SETTINGS_NOTIFY_TRACKING_MESSAGES_SMS_ROUTE = 'notify.tracking-messages.sms'
export const SETTINGS_NOTIFY_TRACKING_MESSAGES_WHATSAPP_ROUTE = 'notify.tracking-messages.whatsapp'

export const btnHelpCenter: RouteButton = {
  id: 'open-help-center',
  title: () => i18n.t('Help center'),
  name: 'Help center button',
  href: getHelpCenterUrl('sms', 'tracking'),
  newTab: true,
  mode: 'borderless',
  icon: faInfoCircle,
  align: 'right',
  permissions: ['configure_tracking'],
}

export const btnHelpCenterWhatsApp: RouteButton = {
  id: 'open-help-center',
  title: () => i18n.t('Help center'),
  name: 'Help center button',
  href: getHelpCenterUrl('whatsapp', 'tracking'),
  newTab: true,
  mode: 'borderless',
  icon: faInfoCircle,
  align: 'right',
  permissions: ['configure_tracking'],
}

export const btnHelpCenterMessagesV2 = {
  id: 'open-help-center',
  title: () => i18n.t('Help center'),
  name: 'Help center button',
  href: getHelpCenterUrl('messages_v2', 'tracking'),
  newTab: true,
  mode: 'borderless' as const,
  icon: faInfoCircle,
  align: 'right' as const,
  permissions: ['configure_tracking'] as (keyof UserPermissions)[],
}

export default {
  name: SETTINGS_NOTIFY_TRACKING_MESSAGES_ROUTE,
  path: useNewNavigation ? '/tracking/messages' : 'messages',
  redirect: { name: SETTINGS_NOTIFY_TRACKING_MESSAGES_EMAIL_ROUTE },
  component: () => import('@/features/tracking-messages/pages/TrackingMessagesPage.vue'),
  meta: {
    pageTitle: () => i18n.t('Tracking messages'),
    permissions: ['configure_tracking'],
  },
  children: [
    {
      name: SETTINGS_NOTIFY_TRACKING_MESSAGES_EMAIL_ROUTE,
      path: useNewNavigation ? '/tracking/email' : 'email',
      component: () => import('@/features/tracking-messages/pages/TrackingEmailPage.vue'),
      props: true,
      meta: {
        pageTitle: () => i18n.t('Tracking messages'),
        permissions: ['configure_tracking'],
        parent: 'tracking-messages',
      },
      alias: ['/settings/track-and-trace/messages/email'],
    },
    {
      name: SETTINGS_NOTIFY_TRACKING_MESSAGES_SMS_ROUTE,
      path: useNewNavigation ? '/tracking/sms' : 'sms',
      component: () => import('@/features/tracking-messages/pages/TrackingSmsPage.vue'),
      props: true,
      meta: {
        pageTitle: () => i18n.t('Tracking messages'),
        permissions: ['configure_tracking'],
        buttons: [btnHelpCenter],
        parent: 'tracking-messages',
      },
      alias: ['/settings/track-and-trace/messages/sms'],
    },
    {
      name: SETTINGS_NOTIFY_TRACKING_MESSAGES_WHATSAPP_ROUTE,
      path: useNewNavigation ? '/tracking/whatsapp' : 'whatsapp',
      component: () => import('@/features/tracking-messages/pages/TrackingWhatsAppPage.vue'),
      props: true,
      meta: {
        pageTitle: () => i18n.t('Tracking messages'),
        permissions: ['configure_tracking'],
        buttons: [btnHelpCenterWhatsApp],
        parent: 'tracking-messages',
      },
      alias: ['/settings/track-and-trace/messages/whatsapp'],
      beforeEnter: betaGuard(BETA_FEATURE_WHATSAPP, SETTINGS_NOTIFY_TRACKING_MESSAGES_ROUTE),
    },
  ],
} satisfies RouteRecordRaw
