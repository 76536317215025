/**
 * This function counts how many integrations from an array are set as billable
 * @param {Array} integrations
 * @returns {number}
 */
export function countBillableIntegrations(integrations) {
  return integrations.reduce(
    (billableIntegrationsCount, integration) =>
      integration.billable === false ? billableIntegrationsCount : billableIntegrationsCount + 1,
    0,
  )
}
