import axios from '@/common/utils/axios'
import { getAbsoluteBackendURL } from '@/common/utils/backend'
import isOnboardingWizardEnabled from '@/features/onboarding-wizard/utils/is-onboarding-wizard-enabled'

import type { Step, StepsResponse, StatusResponse, Id } from '@/features/onboarding-wizard/types/onboarding-wizard.types'

/**
 * Generate the base URL from env variable.
 */
export function generateUrl(): string {
  const baseUrl = getAbsoluteBackendURL(import.meta.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL as string)
  return `${baseUrl}`
}

/**
 * Retrieve the list of steps
 */
export async function fetchAllSteps(): Promise<StepsResponse | null> {
  if (isOnboardingWizardEnabled() !== 'yes') {
    return null
  }

  const url = generateUrl()
  const response = await axios.get<StepsResponse>(`${url}/steps`)
  return response.data
}

/**
 * Retrieve the information of the step id
 */
export async function fetchStep(id: Id): Promise<Step | null> {
  if (isOnboardingWizardEnabled() !== 'yes' || !id) {
    return null
  }

  try {
    const url = generateUrl()
    const response = await axios.get<Step>(`${url}/steps/${id}`)
    return response.data
  } catch (error) {
    return null
  }
}

/**
 * Complete the step in the current step id
 */
export async function completeStep(id: Id): Promise<StatusResponse | null> {
  if (isOnboardingWizardEnabled() !== 'yes' || !id) {
    return null
  }

  const url = generateUrl()
  const response = await axios.patch<StatusResponse>(`${url}/steps/${id}`, { is_complete: true })
  return response.data
}

/**
 * Retrieve the status of the wizard
 */
export async function fetchStatus(): Promise<StatusResponse | null> {
  if (isOnboardingWizardEnabled() !== 'yes') {
    return null
  }

  const url = generateUrl()
  const response = await axios.get<StatusResponse>(`${url}/status`)
  return response.data
}

/**
 * Update the status of the wizard
 */
export async function skipWizard(): Promise<void | null> {
  if (isOnboardingWizardEnabled() !== 'yes') {
    return null
  }

  const url = generateUrl()
  try {
    await axios.patch(`${url}/status`, { is_wizard_skipped: true })
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Post Your Details form data.
 */
async function saveYourDetails(formData: Record<string, unknown>): Promise<void> {
  const url = generateUrl()
  await axios.post(`${url}/your-details`, formData)
}

export default {
  fetchAllSteps,
  fetchStep,
  completeStep,
  fetchStatus,
  skipWizard,
  saveYourDetails,
}
