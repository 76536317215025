import tippy from 'tippy.js'

function tooltipInit(el, binding) {
  if (!binding.value || el._tippy !== undefined) {
    return
  }

  const triggerList = []

  if (binding.modifiers.hover) triggerList.push('mouseenter')
  if (binding.modifiers.click) triggerList.push('click')
  if (binding.modifiers.focus) triggerList.push('focus')
  if (!triggerList.length) triggerList.push('mouseenter')

  tippy(el, {
    content: binding.value,
    placement: binding.arg || 'top',
    trigger: triggerList.join(' '),
  })
}

const tooltip = {
  beforeMount: tooltipInit,
  updated: tooltipInit,
  unmounted(el) {
    if (el._tippy && typeof el._tippy.destroy === 'function') {
      el._tippy.destroy()
    }
  },
}

export default tooltip

// Note:
// Tippy defaults are set up in `src/utils/initialize-tippy.ts`.

// Usage:
// <button v-tooltip="'Tooltip text'">Hover me</button>
// <button v-tooltip.click="'Tooltip text'">Click me</button>
// <button v-tooltip:bottom="'Tooltip text'">Tooltip on the bottom</button>
