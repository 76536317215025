<template>
  <li>
    <button
      :id="id"
      v-tooltip.hover.focus="tooltip ? t('Notifications') : false"
      :class="{ 'notifications__button--active': isActive }"
      class="notifications__button"
      type="button"
      data-test="notifications-button"
      :data-appcues="props.dataAppcues"
      @click="toggleNotificationsList(true)"
    >
      <Fa
        class="top-menu__icon"
        :icon="faBell"
      />

      <span class="sr-only">{{ t('Notifications') }}</span>

      <span
        v-if="unreadCount > 0"
        class="notification__counter"
        data-test="notifications-counter"
      >
        {{ unreadCount }}
      </span>
    </button>
  </li>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Fa from 'vue-fa'
import { useStore } from 'vuex'
import { faBell } from '@fortawesome/pro-solid-svg-icons'
import { useNotificationsStore } from '@/features/notifications/stores'
import vTooltip from '@/common/utils/tooltip.directive'
import { useI18n } from 'vue-i18n'
import Google from '@/common/utils/tracking/google'
import OldNavigationAnalytics from '@/application/utils/old-navigation.analytics'
import { NOTIFICATIONS_TOGGLE_NOTIFICATIONS_LIST } from '@/features/notifications/stores/notifications/action.types'
import { NEW_NOTIFICATIONS_BETA } from '@/features/support/beta-features'
import { NOTIFICATION_ROUTE, NOTIFICATION_DETAILS_ROUTE } from '@/features/notifications/routes'
import { useRoute } from 'vue-router'

const notificationRoutesNames = [NOTIFICATION_ROUTE, NOTIFICATION_DETAILS_ROUTE]

const props = withDefaults(defineProps<{
  tooltip?: boolean
  id?: string
  dataAppcues?: string
}>(), {
  tooltip: false,
  id: '',
  dataAppcues: '',
})

const { t } = useI18n()
const store = useStore()
const route = useRoute()
const notificationsStore = useNotificationsStore()

const { setNotificationPanelVisible } = notificationsStore

const unreadCount = computed(() => {
  if (hasNotificationsBeta.value) {
    return notificationsStore.getUnreadCount
  }
  return store.getters.unreadCount
})
const hasNotificationsBeta = computed(() => store.getters.hasBetaFeature(NEW_NOTIFICATIONS_BETA))
const isActive = computed(() => route.name && notificationRoutesNames.includes(route.name as string))

function toggleNotificationsList(value: boolean) {
  if (hasNotificationsBeta.value) {
    setNotificationPanelVisible(value)
  } else {
    store.dispatch(NOTIFICATIONS_TOGGLE_NOTIFICATIONS_LIST, value)
  }
  Google.trackEvent('Notifications Sidemenu', 'Toggle Menu', '', value)
  OldNavigationAnalytics.trackNavMenuItemClick(props.dataAppcues)
}
</script>

<style lang="scss" scoped>
.notifications__button {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $sc-link-blue;
  font-size: 22px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 15px 10px;
  position: relative;
  width: 100%;

  @include breakpoint-max-width(large) {
    .top-menu__icon {
      display: none;
    }

    .sr-only {
      clip: auto;
      font-weight: 600;
      height: auto;
      margin: 0;
      position: static;
      width: auto;
      overflow: visible;
      font-size: 16px;
    }
  }

  @include breakpoint-min-width(large) {
    align-items: center;
    height: 100%;
    padding: 0 15px;
    width: auto;
  }

  @include breakpoint-min-width(extra-large) {
    padding: 0 20px;
  }

  &::before {
    background-color: #f4faff;
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: -1;

    @include breakpoint-min-width(large) {
      height: 92%;
    }
  }

  &::after {
    background-color: $sc-informative;
    bottom: 0;
    content: '';
    height: 5px;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  &:hover {
    text-decoration: none;

    &::before {
      opacity: 1;
    }
  }

  &:focus {
    text-decoration: none;
  }

  @include breakpoint-min-width(large) {
    &:hover {
      text-decoration: none;

      &::before {
        opacity: 1;
      }
    }

    &:focus {
      text-decoration: none;

      &::after {
        background-color: $sc-focus;
        opacity: 1;
      }
    }
  }
}

.notifications__button--active {
  &::before {
    opacity: 1;
  }

  @include breakpoint-min-width(large) {
    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
      background-color: $sc-informative;
    }

    &:hover,
    &:focus {
      &::before {
        opacity: 1;
      }

      &::after {
        opacity: 1;
        background-color: $sc-informative;
      }
    }
  }
}

.notification__counter {
  align-items: center;
  background-color: $sc-dark-blue;
  border-radius: 50px;
  color: #fff;
  display: flex;
  font-size: 11px;
  height: 16px;
  justify-content: center;
  min-width: 16px;
  padding: 3px;

  @include breakpoint-min-width(large) {
    position: absolute;
    right: 12px;
    top: 23px;
  }
}
</style>
