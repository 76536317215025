import i18n from '@/application/i18n/i18n'
import { faList } from '@fortawesome/pro-solid-svg-icons'
import { isEmailVerified } from '@/features/support/guards/support-email-verified'
import type { RouteRecordRaw } from 'vue-router'
import type { RouteButton } from '@/common/types/route.types'

export const SUPPORT_ROUTE = 'support.tickets'
export const SUPPORT_NEW_TICKET_ROUTE = 'support.newTicket'
export const SUPPORT_TICKET_DETAILS_ROUTE = 'support.ticketDetails'
export const SUPPORT_SHIPMENTS_TICKET_LIST = 'support.shipments.ticketList'
export const SUPPORT_PLATFORM_TICKET_LIST = 'support.platform.ticketList'
export const SUPPORT_TICKET_DRAFT_LIST = 'support.ticketDraftList'
export const SUPPORT_REFUND_CLAIMS = 'support.refundClaims'
export const SUPPORT_CLAIM = 'support.claim'
const SUPPORT_AGENT_LOGIN = 'support.agent-login'

const btnHelpCenter: RouteButton = {
  id: 'open-help-center',
  title: () => i18n.t('Help center'),
  name: 'https://support.sendcloud.com',
  newTab: true,
  icon: faList,
  mode: 'secondary',
  permissions: ['manage_tickets'],
}

const btnListTickets: RouteButton = {
  id: 'support-ticket-list',
  title: () => i18n.t('View tickets'),
  name: SUPPORT_ROUTE,
  icon: faList,
  mode: 'primary',
  permissions: ['manage_tickets'],
}

const btnAgentLogin: RouteButton = {
  id: 'agent-login',
  name: SUPPORT_AGENT_LOGIN,
  title: () => i18n.t('Agent login'),
  mode: 'secondary',
  icon: faList,
  permissions: ['manage_tickets'],
}

export default [
  {
    name: SUPPORT_ROUTE,
    path: '/support',
    component: () => import('@/features/support/components/SupportNav.vue'),
    redirect: { name: SUPPORT_SHIPMENTS_TICKET_LIST },
    meta: {
      pageTitle: () => i18n.t('Support'),
      permissions: ['manage_tickets'],
      buttons: [btnHelpCenter, btnAgentLogin],
      showUserId: true,
      parent: 'support',
    },
    children: [
      {
        name: SUPPORT_SHIPMENTS_TICKET_LIST,
        path: '/support/tickets/shipments',
        component: () => import('@/features/support/pages/SupportTicketListShipmentsPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Support'),
          permissions: ['manage_tickets'],
          parent: 'support',
        },
      },
      {
        name: SUPPORT_PLATFORM_TICKET_LIST,
        path: '/support/tickets/platform',
        component: () => import('@/features/support/pages/SupportTicketListPlatformPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Support'),
          permissions: ['manage_tickets'],
          parent: 'support',
        },
      },
      {
        name: SUPPORT_TICKET_DRAFT_LIST,
        path: '/support/tickets/drafts',
        component: () => import('@/features/support/pages/SupportTicketListDraftsPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Support'),
          permissions: ['manage_tickets'],
          parent: 'support',
        },
      },
    ],
  },
  {
    name: SUPPORT_NEW_TICKET_ROUTE,
    path: '/support/new-ticket',
    beforeEnter: isEmailVerified,
    component: () => import('@/features/support/pages/SupportNewTicketFormPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Support'),
      permissions: ['manage_tickets'],
      buttons: [btnListTickets, btnHelpCenter],
      showUserId: true,
      parent: 'support',
    },
    props: route => ({
      presetSupportTypeId: route.query.supportType ?? null,
      presetTrackingNumber: route.query.trackingNumber ?? null,
    }),
  },
  {
    name: SUPPORT_TICKET_DETAILS_ROUTE,
    path: '/support/ticket/:ticketType/:id',
    beforeEnter: isEmailVerified,
    props: route => ({
      ticketType: route.params.ticketType,
      id: Number.parseInt(route.params.id as string),
    }),
    component: () => import('@/features/support/pages/SupportTicketDetailsPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Support'),
      permissions: ['manage_tickets'],
      buttons: [btnListTickets, btnHelpCenter],
      showUserId: true,
      parent: 'support',
    },
  },
  {
    name: SUPPORT_REFUND_CLAIMS,
    path: '/support/refund-claim/:action(accept|wait)',
    beforeEnter: isEmailVerified,
    props: route => ({
      action: route.params.action,
      payload: route.query.payload,
    }),
    component: () => import('@/features/support/pages/RefundClaimPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Support'),
      permissions: ['manage_tickets'],
      buttons: [btnListTickets, btnHelpCenter],
      showUserId: true,
      parent: 'support',
    },
  },
  {
    name: SUPPORT_CLAIM,
    path: '/support/claim/manage/:payload',
    beforeEnter: isEmailVerified,
    props: route => ({
      payload: route.params.payload,
    }),
    component: () => import('@/features/support/pages/GenericRefundClaimPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Support'),
      permissions: ['manage_tickets'],
      buttons: [btnListTickets, btnHelpCenter],
      showUserId: true,
      parent: 'support',
    },
  },
] satisfies RouteRecordRaw[]
