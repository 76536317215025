import type { RouteRecordRaw } from 'vue-router'
import { faPlus, faUpload } from '@fortawesome/pro-solid-svg-icons'

import i18n from '@/application/i18n/i18n'
import type { RouteButton } from '@/common/types/route.types'

export const SHIPPING_ACTION_REQUIRED_ROUTE = 'parcels.list.actionRequired'
export const SHIPPING_CANCELLED_ROUTE = 'parcels.list.cancelled'
export const SHIPPING_CREATED_LABELS_ROUTE = 'parcels.list.createdLabels'
export const SHIPPING_IN_TRANSIT_ROUTE = 'parcels.list.inTransit'
export const SHIPPING_REPORTS_ROUTE = 'parcels.list.reports'

export const SHIPPING_BUTTONS: RouteButton[] = [
  {
    // @ts-ignore
    title: () => i18n.t('New shipment'),
    name: 'parcels.new',
    icon: faPlus,
    mode: 'secondary',
    dataTest: 'smoke-test-new-shipment-form-link',
    dataSmokeTest: 'new-shipment-form-link',
    permissions: ['manage_shipping'],
  },
  {
    title: () => i18n.t('Upload file'),
    name: 'base.csv-importer.upload',
    icon: faUpload,
    mode: 'borderless',
    permissions: ['import_orders'],
  },
]

export default [
  {
    name: SHIPPING_CREATED_LABELS_ROUTE,
    path: '/shipping/list/labels',
    component: () => import('@/features/shipment-tabs/pages/CreatedLabelsPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Created labels'),
      permissions: ['view_parcels'],
      buttons: SHIPPING_BUTTONS,
      parent: 'shipping-orders',
    },
  },
  {
    name: SHIPPING_IN_TRANSIT_ROUTE,
    path: '/shipping/list/in-transit',
    component: () => import('@/features/shipment-tabs/pages/ShippedPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Shipped'),
      permissions: ['view_parcels'],
      buttons: SHIPPING_BUTTONS,
      parent: 'shipping-orders',
    },
  },
  {
    name: SHIPPING_CANCELLED_ROUTE,
    path: '/shipping/list/cancelled',
    component: () => import('@/features/shipment-tabs/pages/CancelledPage.vue'),
    meta: {
      pageTitle: (): string => 'Cancelled',
      permissions: ['view_parcels'],
      buttons: SHIPPING_BUTTONS,
      parent: 'shipping-orders',
    },
  },
  {
    name: SHIPPING_ACTION_REQUIRED_ROUTE,
    path: '/shipping/list/pending',
    component: () => import('@/features/shipment-tabs/pages/ActionRequiredPage.vue'),
    meta: {
      pageTitle: () => i18n.t('Action required'),
      permissions: ['manage_shipping'],
      buttons: SHIPPING_BUTTONS,
      parent: 'shipping-orders',
    },
  },
  {
    name: SHIPPING_REPORTS_ROUTE,
    path: '/shipping/list/reports',
    component: () => import('@/features/shipment-tabs/pages/ReportsPage.vue'),
    meta: {
      pageTitle: (): string => 'Reports',
      permissions: ['manage_pickup_reports'],
      buttons: SHIPPING_BUTTONS,
      parent: 'shipping-orders',
    },
  },
] satisfies RouteRecordRaw[]
