<template>
  <div
    :class="progressBarClass"
    class="onboarding-wizard-progress-bar"
  >
    <p
      v-if="size !== 'small'"
      class="onboarding-wizard-progress-bar_percentage-text"
      data-test="onboarding-wizard-progress-bar-percentage-text"
    >
      {{ progressPercentage }}% {{ t('Complete') }}
    </p>
    <div class="onboarding-wizard-progress-bar__bar">
      <div
        class="onboarding-wizard-progress-bar__percentage-bar"
        :style="{ width: progressPercentage + '%' }"
      >
        <Fa
          v-if="size !== 'small'"
          :icon="faCircleCheck"
          class="onboarding-wizard-progress-bar__icon ui-icon--medium"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import Fa from 'vue-fa'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'

const { t } = useI18n()

// Props
const props = defineProps<{
  size?: 'large' | 'medium' | 'small'
}>()

// Default value for size prop
const size = computed(() => props.size || 'large')

// Store
const store = useStore()

// Computed properties
const progressPercentage = computed(() => {
  const completed: number = store.getters.onboardingWizardStepsCompleted
  const total: number = store.getters.onboardingWizardStepsTotal
  return Math.round((completed / total) * 100)
})

const progressBarClass = computed(() => ({
  'onboarding-wizard-progress-bar--medium': size.value === 'medium',
  'onboarding-wizard-progress-bar--small': size.value === 'small',
}))

</script>

<style lang="scss">
.onboarding-wizard-progress-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: $sc-spacing-16;
  align-items: center;
}

.onboarding-wizard-progress-bar__bar {
  position: relative;
  width: 100%;
  height: 20px;
  border-radius: $sc-border-radius;
  overflow: hidden;
  border: none;
  display: flex;
  align-items: center;
  gap: 16px;

  @include breakpoint-min-width(medium) {
    width: 270px;
  }

  &::before {
    content: '';
    display: block;
    height: 60%;
    width: 100%;
    left: 0;
    position: absolute;
    background-color: $sc-light-grey;
    border-radius: $sc-border-radius;
  }
}

.onboarding-wizard-progress-bar__percentage-bar {
  position: relative;
  display: flex;
  align-items: center;
  height: inherit;

  &::before {
    content: '';
    display: block;
    height: 60%;
    width: 100%;
    left: 0;
    position: absolute;
    border-radius: $sc-border-radius;
    background-color: $sc-positive;
  }
}

.onboarding-wizard-progress-bar_percentage-text {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  min-width: 120px;
}

.onboarding-wizard-progress-bar__icon {
  color: $sc-positive;
  position: absolute;
  right: -5px;
  top: 0;
  background: $sc-white;
  z-index: 999;
  border-radius: 10px;
}

.onboarding-wizard-progress-bar--medium {
  flex-direction: column;
  align-items: flex-start;

  .onboarding-wizard-progress-bar__bar {
    @include breakpoint-min-width(medium) {
      width: 100%;
    }
  }
}

.onboarding-wizard-progress-bar--small {
  .onboarding-wizard-progress-bar__bar {
    height: 10px;

    @include breakpoint-min-width(medium) {
      width: 100%;
    }
  }
}
</style>
