/** @typedef {import('@/types/models').PrintClientDownloadLinks} PrintClientDownloadLinks */
/** @typedef {import('@/types/models').InstalledPrintClient} InstalledPrintClient  */

import store from '@/common/stores/store'

import OsDetectorService, { SYSTEMS, BROWSERS } from '@/features/printing/services/os-detector.service'

const SUPPORTED_SYSTEMS = [SYSTEMS.WINDOWS, SYSTEMS.MACOS]
const SUPPORTED_BROWSERS = [BROWSERS.CHROME, BROWSERS.FIREFOX]

export default {
  latestVersion: undefined,
  installedPrintClient: {
    platform: undefined,
    type: undefined,
    version: undefined,
  },

  /** @return {PrintClientDownloadLinks} */
  getDownloadUrls() {
    const downloadUrls = store.getters.definitions.desktop_client.download_url
    const osKey = this.getOsKey()

    if (osKey && downloadUrls[osKey] !== undefined) {
      if (typeof downloadUrls[osKey] === 'string') {
        return { i386: downloadUrls[osKey] }
      } else if (osKey === SYSTEMS.MACOS.toLowerCase()) {
        if (this.isAppleSiliconProcessor()) {
          return { arm: downloadUrls[osKey]['arm'] }
        } else {
          return { i386: downloadUrls[osKey]['i386'] }
        }
      } else {
        return downloadUrls[osKey]
      }
    }
    return null
  },

  /** @return {string} */
  getOsKey() {
    const os = OsDetectorService.getSystem()
    if (os) {
      return os.toLowerCase()
    }

    return undefined
  },

  /** @return {string} */
  getVersions() {
    if (this.latestVersion === null || this.latestVersion === undefined) {
      this.loadVersionsFromDefinitions()
    }

    return this.latestVersion
  },

  /** @return {InstalledPrintClient} */
  getInstalledPrintClientVersion() {
    return this.installedPrintClient
  },

  /** @return {boolean} */
  hasNewVersion() {
    if (this.latestVersion === null || this.latestVersion === undefined) {
      this.loadVersionsFromDefinitions()
    }

    if (this.installedPrintClient.version === null || this.installedPrintClient.version === undefined) {
      return false
    }

    return this.isUpgrade(this.latestVersion, this.installedPrintClient.version)
  },

  /** @return {boolean} */
  isBrowserSupported() {
    return SUPPORTED_BROWSERS.includes(OsDetectorService.getBrowser())
  },

  /** @return {boolean} */
  isOperatingSystemSupported() {
    return SUPPORTED_SYSTEMS.includes(OsDetectorService.getSystem())
  },

  /**
   * Checks if the current environment support the Print Client.
   *
   * @return {boolean}
   */
  isSupported() {
    return this.isOperatingSystemSupported() && this.isBrowserSupported()
  },

  /**
   * @param {string} newVersion
   * @param {string} oldVersion
   * @returns {boolean}
   */
  isUpgrade(newVersion, oldVersion) {
    if (!newVersion || !oldVersion) {
      return false
    }

    const a = newVersion.split('.').map(Number)
    const b = oldVersion.split('.').map(Number)
    return a[0] > b[0] || (a[0] === b[0] && (a[1] > b[1] || (a[1] === b[1] && a[2] > b[2])))
  },

  loadVersionsFromDefinitions() {
    const osKey = this.getOsKey()
    if (osKey && store.getters.definitions?.desktop_client.versions[osKey] !== undefined) {
      const versions = store.getters.definitions.desktop_client.versions

      if (typeof versions[osKey] === 'string') {
        this.latestVersion = versions[osKey]
      } else if (this.installedPrintClient.type !== undefined && versions[osKey][this.installedPrintClient.type]) {
        this.latestVersion = versions[osKey][this.installedPrintClient.type]
      } else if (this.installedPrintClient.type === undefined && versions[osKey]['i386']) {
        this.latestVersion = versions[osKey]['i386']
      }
    }
  },

  /**
   * @param {InstalledPrintClient} installedPrintClient
   */
  updateInstalledVersions(installedPrintClient) {
    this.installedPrintClient = installedPrintClient
  },

  isAppleSiliconProcessor() {
    const canvas = document.createElement('canvas')
    if (!canvas?.getContext) {
      return false
    }

    const webglContext = canvas.getContext('webgl')
    if (!webglContext || !webglContext.getExtension('WEBGL_debug_renderer_info')) {
      return false
    }

    const gpu = webglContext.getParameter(webglContext.getExtension('WEBGL_debug_renderer_info').UNMASKED_RENDERER_WEBGL)

    return gpu?.match(/Apple M/) ? true : false
  },
}
