import store from '@/common/stores/store'
import { SUPPORT_ROUTE } from '@/features/support/routes'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

/**
 * Check if the user e-mail has been verified.
 * - If true, go to the legacy NewTicket form.
 * - If false, go to to the ticket list.
 */
export const isEmailVerified = (
  _to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext,
) => {
  if (store.getters.user.is_approved) {
    next()
  } else {
    next({ name: SUPPORT_ROUTE })
  }
}
