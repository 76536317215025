import * as ReturnRulesService from '@/features/return-rules/api/return-rules.api'

import {
  RETURN_RULES_CREATE_RULE,
  RETURN_RULES_DELETE_RULE,
  RETURN_RULES_FETCH_RULE,
  RETURN_RULES_FETCH_RULES,
  RETURN_RULES_MOVE_RULES,
  RETURN_RULES_TOGGLE_RULE,
  RETURN_RULES_UPDATE_RULE,
} from '@/features/return-rules/stores/return-rules/action.types'

import { RETURN_RULES_SET_RETURN_RULE, RETURN_RULES_SET_RETURN_RULES } from '@/features/return-rules/stores/return-rules/mutation.types'

const state = {
  returnRules: [],
}

const getters = {
  returnRules: state => state.returnRules,
  returnRuleById: state => ruleId => state.returnRules.find(returnRule => returnRule.id === ruleId),
}

const actions = {
  async [RETURN_RULES_CREATE_RULE]({ dispatch }, returnRule) {
    await ReturnRulesService.createReturnRule(returnRule)
    dispatch(RETURN_RULES_FETCH_RULES)
  },

  async [RETURN_RULES_DELETE_RULE]({ dispatch }, returnRuleId) {
    await ReturnRulesService.deleteReturnRule(returnRuleId)
    dispatch(RETURN_RULES_FETCH_RULES)
  },

  async [RETURN_RULES_FETCH_RULE]({ commit }, id) {
    try {
      const response = await ReturnRulesService.fetchReturnRule(id)
      const returnRule = response.data
      commit(RETURN_RULES_SET_RETURN_RULE, returnRule)
    } catch (error) {
      // New users can’t access return rules.
    }
  },

  async [RETURN_RULES_FETCH_RULES]({ commit }) {
    try {
      const response = await ReturnRulesService.fetchReturnRules()
      const returnRules = response.data
      commit(RETURN_RULES_SET_RETURN_RULES, returnRules)
    } catch (error) {
      // New users can’t access return rules.
    }
  },

  async [RETURN_RULES_MOVE_RULES]({ dispatch }, reorderPayload) {
    await ReturnRulesService.reorderReturnRules(reorderPayload)
    dispatch(RETURN_RULES_FETCH_RULES)
  },

  async [RETURN_RULES_TOGGLE_RULE]({ dispatch }, { returnRuleId, patchPayload }) {
    await ReturnRulesService.toggleReturnRule(returnRuleId, patchPayload)
    dispatch(RETURN_RULES_FETCH_RULES)
  },

  async [RETURN_RULES_UPDATE_RULE]({ dispatch }, returnRule) {
    await ReturnRulesService.updateReturnRule(returnRule)
    dispatch(RETURN_RULES_FETCH_RULES)
  },
}

const mutations = {
  [RETURN_RULES_SET_RETURN_RULE](state, returnRule) {
    state.returnRules = [...(state.returnRules || []), returnRule]
  },
  [RETURN_RULES_SET_RETURN_RULES](state, returnRules) {
    state.returnRules = returnRules
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
