import * as Sentry from '@sentry/vue'

import { shouldReportError } from '@/common/utils/errors/should-report-error'

const showConsoleErrors = import.meta.env.VUE_APP_SHOULD_LOG_TO_CONSOLE === 'yes'

/**
 * Handle any unhandled errors thrown by components.
 *
 * In development environment, the error will be logged to the console.
 *
 * In a production environment or an environment where Sentry is enabled, the error will be sent to Sentry.
 */
export const globalErrorHandler = (error: unknown): void => {
  logError(error)
}

export const logError = <E>(
  error: E,
  { formatError }: { formatError?: (e: E) => unknown } = {},
) => {
  const formattedError = formatError ? formatError(error) : error
  if (showConsoleErrors) {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  if (shouldReportError(error)) {
    Sentry.captureException(formattedError)
  }
}

/**
 * Registers fullscreen error overlay for passed error
 */
export const showFullscreenErrorOverlay = (err: Error) => {
  const ErrorOverlay = customElements.get('vite-error-overlay')
  if (!ErrorOverlay) {
    return
  }
  const overlay = new ErrorOverlay(err)
  document.body.appendChild(overlay)
}
