/** @type {import('node_modules/vue-router/types/index').RouteConfig[]} */
import i18n from '@/application/i18n/i18n'
import { returnRuleDetailsGuard } from '@/features/return-rules/guards/return-rules.guard.js'
import { RouterView, type RouteLocationNormalized, type RouteRecordRaw } from 'vue-router'

export const SETTINGS_RETURN_RULES_LIST_ROUTE = 'return-rules.list'
export const SETTINGS_RETURN_RULES_ADD_ROUTE = 'return-rules.add'
export const SETTINGS_RETURN_RULES_EDIT_ROUTE = 'return-rules.edit'

export default {
  path: 'return-rules',
  component: RouterView,
  children: [
    {
      name: SETTINGS_RETURN_RULES_LIST_ROUTE,
      path: '',
      component: () => import('@/features/return-rules/pages/ReturnRulesListPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Return rules'),
        permissions: ['configure_returns'],
        parent: 'returns-rules',
      },
    },
    {
      name: SETTINGS_RETURN_RULES_ADD_ROUTE,
      path: 'add',
      component: () => import('@/features/return-rules/pages/ReturnRulesDetailsPage.vue'),
      beforeEnter: returnRuleDetailsGuard,
      meta: {
        pageTitle: () => i18n.t('New return rule'),
        permissions: ['configure_returns'],
        parent: 'returns-rules',
      },
    },
    {
      name: SETTINGS_RETURN_RULES_EDIT_ROUTE,
      path: ':ruleId',
      component: () => import('@/features/return-rules/pages/ReturnRulesDetailsPage.vue'),
      props: (route: RouteLocationNormalized) => ({
        ruleId: Number.parseInt(route.params.ruleId as string),
      }),
      beforeEnter: returnRuleDetailsGuard,
      meta: {
        pageTitle: () => i18n.t('Edit return rule'),
        permissions: ['configure_returns'],
        parent: 'returns-rules',
      },
    },
  ],
} satisfies RouteRecordRaw
