import * as Sentry from '@sentry/vue'
import { printAxiosClient } from '@/common/utils/axios'
import { hasAbsoluteBackendURL, redirectToAccountsService, getAbsoluteBackendURL } from '@/common/utils/backend'
import { loadLocaleAsync } from '@/application/i18n/i18n'
import store from '@/common/stores/store'

import InitialStateService, { BootupError } from '@/common/services/initial-state.service'
import ToastService from '@/common/services/toast.service'
import SupportChatService from '@/features/support/services/support-chat.service'
import startPollingForNotifications from '@/features/notifications/utils/start-polling-for-notifications'
import { pruneStorageOfOtherUsers } from '@/application/utils/prune-storage-of-other-users'
import { installTrackingTools } from '@/common/utils/tracking/index'
import Segment from '@/common/utils/tracking/segment'
import { configureSpeedCurveLux, IS_SPEEDCURVE_ENABLED } from '@/common/utils/performance-monitoring/speedcurve'

import { setUpPrintClientInterceptor } from '@/application/utils/set-up-print-client-interceptor'
import DateTime from '@/common/utils/date-time'

import initializeTippy from '@/application/utils/initialize-tippy'
import { isAxiosError } from 'axios'

const showConsoleErrors = import.meta.env.VUE_APP_SHOULD_LOG_TO_CONSOLE === 'yes'
const isSentryEnabled = import.meta.env.VUE_APP_SENTRY_ENABLED === 'yes'

/**
 * Populates the store with the mandatory information in order to run the app and sets up tracking.
 * If the user is not authenticated or the backend pod URL could not be determined,
 * the user is redirected to the login page.
 *
 * Returns `true` if the user is authenticated and the backend pod URL is determined, otherwise `false`.
 */
export async function prepareApp(): Promise<boolean> {
  if (!hasAbsoluteBackendURL()) {
    redirectToAccountsService()
    return false
  }

  ToastService.setup()

  try {
    await InitialStateService.fetchInitialStoreData()

    setUpPrintClientInterceptor(printAxiosClient)
    initializeTippy()
    startPollingForNotifications()

    if (store.getters.user && store.getters.definitions) {
      const isUserAnalyticsEnabled = store.getters.definitions.enable_user_analytics

      if (isSentryEnabled) {
        Sentry.configureScope(function (scope) {
          // Limit the data sent to Sentry to avoid leaking PII (e.g. email, telephone, etc).
          scope.setUser({ id: store.getters.user.id })
          // Intentionally not adding user_locale via scope.setUser() to enable filtering on this property
          scope.setTag('user_locale', store.getters.user.language)
        })
      }

      await loadLocaleAsync(store.getters.userLanguage, store.getters.user.currency)
      pruneStorageOfOtherUsers(store.getters.user.id, store.getters.isHijacked)
      configureSpeedCurveLux(isUserAnalyticsEnabled, IS_SPEEDCURVE_ENABLED)
      installTrackingTools(store.getters.user, store.getters.definitions)
      Segment.identify()
      DateTime.setTimeZoneName(store.getters.user.time_zone_name)
    }

    if (SupportChatService.isChatWidgetEnabled()) {
      SupportChatService.startSmoochWidget()
    }
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 403) {
      redirectToAccountsService()

      return false
    } else if (error instanceof BootupError) {
      // BootupError is thrown from initial-state.service.ts and always indicates a critical, unrecoverable error.
      // Redirect to the startup error page in the backend.
      window.location.href = getAbsoluteBackendURL('/errors/startup/')
      return false
    }

    if (showConsoleErrors) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  return true
}
