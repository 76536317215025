/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type { AnyKey, PrimitiveName, PrimitiveTypesMap } from '../types/type-helpers.types'

export const tryConvertToNumber = (key: string) => {
  const numKey = +key
  // we check for NaN if key was converted to number
  return Number.isFinite(numKey) ? numKey : key
}

export const generateErrorMessage = ({
  inContextOf,
  path,
  expected,
  got,
}: {
  path: AnyKey[]
  inContextOf?: string
  expected: string
  got: string
}) =>
  `${inContextOf ? `In context of "${inContextOf}": ` : ''}${
    path.length ? `at path "${path.join('.')}" ` : ''
  }expected ${expected}, got ${got}`

export const generateShortErrorMessage = ({ path, expected, got }: { path: AnyKey[], expected: string, got: string }) =>
  `${path.length ? `"${path.join('.')}" ` : ''}expected ${expected}, got ${got}`

export const toReadableTypeOf = (data: unknown): string => {
  if (data === null) {
    return 'null'
  }
  if (Array.isArray(data)) {
    return 'array'
  }

  if (typeof data === 'number' && isNaN(data)) {
    return 'NaN'
  }

  return typeof data
}

export const toReadableLiteral = (data: unknown) => {
  if (typeof data === 'object') {
    return toReadableTypeOf(data)
  }

  if (typeof data === 'string') {
    return `"${data}"`
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${data}`
}

export const getErrorMsg = (e: unknown) => {
  if (e && typeof e === 'object' && 'message' in e && typeof e.message === 'string') {
    return e.message
  }

  if (typeof e === 'string') {
    return e
  }

  return e?.toString?.() || 'unknown error'
}

export const doNothing = (): void => undefined

export const keysOf = <T extends object>(obj: T) => Object.keys(obj) as Array<keyof T>

export const createTypeOfAssertion =
  <T extends PrimitiveName>(typeName: T) =>
    (data: unknown): data is PrimitiveTypesMap[T] =>
      typeof data === typeName

export const getTruncatedErrorsText = (
  errors: Array<string | undefined>,
  maxErrorsInOneMsg: number,
  errorCount: number,
) => {
  if (errorCount === 1) {
    return errors[0]
  }
  const errorsText = errors.slice(0, maxErrorsInOneMsg).join('; ')
  const extraErrorsText = errorCount > maxErrorsInOneMsg ? `; ...+${errorCount - maxErrorsInOneMsg} error(s)` : ''

  return `${errorsText}${extraErrorsText}`
}
