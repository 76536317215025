import DateTime from '@/common/utils/date-time'

import i18n from '@/application/i18n/i18n'

/**
 * @param {number|bigint|string} value
 * @param {number} [places]
 * @param {string} [currency]
 * @returns {string}
 */
export function currency(value, places = undefined, currency = undefined) {
  const currencyFormatOptions = { ...i18n.numberFormats[i18n.locale].currency }

  if (currency) {
    currencyFormatOptions.currency = currency
  }

  if (typeof places === 'number') {
    currencyFormatOptions.minimumFractionDigits = places
  }

  const numberCurrencyFormat = new Intl.NumberFormat(i18n.locale, currencyFormatOptions)
  return numberCurrencyFormat.format(value)
}

/**
 * Filters the POD ID from the invoice reference
 *
 * @param {string} invoiceId
 * @returns {string}
 */
export function invoiceRef(invoiceId) {
  const idRegExp = /[\dA-Z-]+-(?<invoice>[\d]{2}-[A-Z]{2}[\d]{7})$/g
  const matches = [...invoiceId.matchAll(idRegExp)]
  return matches[0]?.groups?.invoice || invoiceId
}

/**
 * @param {number|bigint} value
 * @param {number} [places]
 * @returns {string} Value in local format
 */
export function number(value, places = undefined) {
  const numberFormatOptions = { ...i18n.numberFormats[i18n.locale].number }

  if (typeof places === 'number') {
    numberFormatOptions.minimumFractionDigits = places
  }

  const numberFormat = new Intl.NumberFormat(i18n.locale, numberFormatOptions)
  return numberFormat.format(value)
}

export const shortDate = date => DateTime.format('L', date)
export const shortDateMonthText = date => DateTime.format('MMM D, Y LT', date)
export const shortDatetime = date => DateTime.format('L LT', date)
export const longDate = date => DateTime.format('LLL', date)
export const timeToNow = date => DateTime.toNow(date)
export const timeFromNow = date => DateTime.fromNow(date)
export const timeToCalendar = date => DateTime.calendar(date)
