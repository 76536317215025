/** @typedef {import('vuex').ActionContext} ActionContext */
/** @typedef {import('vuex').ActionTree} ActionTree */
// eslint-disable-next-line @stylistic/max-len
/** @typedef {import('@/features/dynamic-checkout-rules/types/checkout-rules.types').BaseCheckoutRule} BaseCheckoutRule */
// eslint-disable-next-line @stylistic/max-len
/** @typedef {import('@/features/dynamic-checkout-rules/types/checkout-rules.types').CheckoutRule} CheckoutRule */

import {
  CHECKOUT_RULES_CREATE_RULE,
  CHECKOUT_RULES_DELETE_RULE,
  CHECKOUT_RULES_FETCH_RULES,
  CHECKOUT_RULES_FETCH_RULE,
  CHECKOUT_RULES_UPDATE_RULE,
} from '@/features/dynamic-checkout-rules/stores/action.types.js'

import { CHECKOUT_RULES_SET_RULE, CHECKOUT_RULES_SET_RULES } from '@/features/dynamic-checkout-rules/stores/mutation.types.js'

import {
  createCheckoutRule,
  fetchCheckoutRules,
  fetchCheckoutRule,
  deleteCheckoutRule,
  updateCheckoutRule,
} from '@/features/dynamic-checkout-rules/api/checkout-rules.api'

const state = {
  checkoutRules: [],
}

const getters = {
  checkoutRules: state => state.checkoutRules,
  checkoutRuleById: state => ruleId => state.checkoutRules.find(checkoutRule => checkoutRule.id === ruleId),
}

/**
 * @type {ActionTree}
 */
const actions = {
  /**
   * @param {ActionContext} context
   */
  async [CHECKOUT_RULES_FETCH_RULES]({ commit }) {
    const checkoutRules = await fetchCheckoutRules()
    commit(CHECKOUT_RULES_SET_RULES, checkoutRules)
  },

  /**
   * @param {ActionContext} context
   * @param {BaseCheckoutRule} checkoutRule
   */
  async [CHECKOUT_RULES_CREATE_RULE]({ dispatch }, checkoutRule) {
    await createCheckoutRule(checkoutRule)
    dispatch(CHECKOUT_RULES_FETCH_RULES)
  },

  /**
   * @param {ActionContext} context
   * @param {number} ruleId
   */
  async [CHECKOUT_RULES_DELETE_RULE]({ dispatch }, ruleId) {
    await deleteCheckoutRule(ruleId)
    dispatch(CHECKOUT_RULES_FETCH_RULES)
  },

  /**
   * @param {ActionContext} context
   * @param {number} ruleId
   */
  async [CHECKOUT_RULES_FETCH_RULE]({ commit }, ruleId) {
    const checkoutRule = await fetchCheckoutRule(ruleId)
    commit(CHECKOUT_RULES_SET_RULE, checkoutRule)
  },

  /**
   * @param {ActionContext} context
   * @param {CheckoutRule} checkoutRule
   */
  async [CHECKOUT_RULES_UPDATE_RULE]({ dispatch }, checkoutRule) {
    await updateCheckoutRule(checkoutRule)
    dispatch(CHECKOUT_RULES_FETCH_RULES)
  },
}

const mutations = {
  /**
   * @param {object} state
   * @param {CheckoutRule[]} checkoutRules
   */
  [CHECKOUT_RULES_SET_RULES](state, checkoutRules) {
    state.checkoutRules = checkoutRules
  },

  /**
   * @param {object} state
   * @param {CheckoutRule} checkoutRule
   */
  [CHECKOUT_RULES_SET_RULE](state, checkoutRule) {
    state.checkoutRules = [...(state.checkoutRules || []), checkoutRule]
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
