import store from '@/common/stores/store'

import IntegrationService from '@/features/integrations/services/integrations.service'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { countBillableIntegrations } from '@/features/integrations/utils/count-billable-integrations'

/**
 * Check if the user has reached the limit for the number of integrations on their plan.
 */
async function hasReachedIntegrationLimit() {
  const user = store.getters.user

  if (user.features.integrations === -1) {
    return false
  } else {
    const integrations = await IntegrationService.findAll()
    const billableIntegrations = countBillableIntegrations(integrations)
    return billableIntegrations > user.features.integrations
  }
}

/**
 * Prevent users from accessing the add route if they have too many integrations.
 * @param {import('node_modules/vue-router/types/index').Route} _to
 * @param {import('node_modules/vue-router/types/index').Route} _from
 * @param {import('node_modules/vue-router/types/index').NavigationGuardNext} next
 */
async function integrationsRouteGuard(_to, _from, next) {
  const shouldPreventAddingNewIntegrations = await hasReachedIntegrationLimit()
  if (shouldPreventAddingNewIntegrations) {
    next({ name: SHIPPING_INCOMING_ORDERS_ROUTE })
  } else {
    next()
  }
}

export default integrationsRouteGuard
