import { wait } from './wait'

/**
 * Calls a given function and keeps calling it after the specified delay has passed.
 *
 * Note: This implementation copies [poll][1] to allow our build process to transpile
 * the package.
 *
 * [1]: https://www.npmjs.com/package/poll
 *
 * @param {() => any} fn The function to call.
 * @param {number} delay The delay (in milliseconds) to wait before calling the function again.
 * @param {() => boolean} shouldStopPolling A callback function indicating whether to stop polling.
 */
async function poll(fn, delay, shouldStopPolling = () => false) {
  if (typeof delay !== 'number') {
    throw new TypeError(`Expected “delay” to be of type number, but it was of type ${typeof delay}.`)
  }

  delay = Math.max(0, delay)

  do {
    await fn()

    if (shouldStopPolling()) {
      break
    }

    await wait(delay)
  } while (!shouldStopPolling())
}

export default poll
