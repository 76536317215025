/** @typedef {import('@/types/models').InvoiceAddress} InvoiceAddress */

import axios from '@/common/utils/axios'

export default {
  /**
   * @returns {Promise<{ data: InvoiceAddress[] }>}
   */
  findAll() {
    return axios.get('/xhr/invoiceaddress')
  },

  /**
   * @param {InvoiceAddress} invoiceAddress
   * @returns {Promise<{ data: InvoiceAddress }>}
   */
  update(invoiceAddress) {
    return axios.patch(`/xhr/invoiceaddress/${invoiceAddress.id}`, invoiceAddress)
  },
}
