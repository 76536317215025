import axios from '@/common/utils/axios'
import i18n from '@/application/i18n/i18n'
import type { ReturnRequisitesPayload, ReturnRequisitesResponse } from '@/types/returns'
import type { Return } from '@/features/returns/types'

/**
 * todo(Jason): move this file to `@/src/features/returns/returns.api`
 */
export default {
  async find(id: number | string) {
    return axios.get(`/xhr/return/${id}`)
  },

  async save(payload: { id: number | string }) {
    return axios.put(`/xhr/return/${payload.id}`, payload)
  },

  async update(return_id: number | string, payload: Return) {
    return axios.patch(`/xhr/return/${return_id}`, payload)
  },

  async resendLabel(id: number | string) {
    return axios.post(`/xhr/return/${id}/resend_label`)
  },

  async refundPaidReturn(id: number | string) {
    return axios.post(`/xhr/return/${id}/refund-return-fee`)
  },

  async cancelLabel(id: number | string) {
    return axios.put(`/xhr/return/${id}/cancel`)
  },

  async fetchStaticData() {
    return axios.get('/xhr/return-portal/static-data')
  },

  async export(from_datetime: string, to_datetime: string) {
    return axios.post(`/xhr/return/export`, { from_datetime, to_datetime })
  },

  async bulkCancel(idList: Array<number | string>) {
    const response = await axios.post(
      '/xhr/return/cancel',
      {},
      {
        params: { ids: idList.join(',') },
      },
    )
    const summary = response.data.summary
    let message
    if (summary.cancelled.length === 0) {
      // @ts-ignore -- angry i18n typescript errors
      message = i18n.t('These shipments cannot be cancelled.')
    } else if (summary.cancelled.length < summary.processed) {
      // At least one parcel got queued, so let's just display
      // a generic message static that "we are working on it" in the
      // background.
      message = i18n.t(
        'We are processing your cancellation request(s), you can follow up on the progress via the shipment status.',
      )
    } else if (summary.failed.length === 0) {
      // Nothing failed, nothing queued, so must be all good...
      message = i18n.t('The shipments were cancelled.')
    } else {
      message = i18n.t('The shipments were partly cancelled.')
    }
    response.data.message = message
    return response.data
  },

  exportAsCsv(returnIds: Array<number | string>) {
    return axios.post('/xhr/return/export-csv', returnIds)
  },

  getReturnAvailableMethods() {
    return axios.get('/xhr/return/methods')
  },

  async getReturnRequisites(payload: ReturnRequisitesPayload[]): Promise<ReturnRequisitesResponse[]> {
    const res = await axios.post('/xhr/return/requisites', payload)

    return res.data
  },
}
