import { getUserStorage, PUBLIC_STORAGE_KEYS, removeVariable, STORAGE_PREFIX } from '@/common/utils/storage'

/**
 * Prune localStorage entries not belonging to the current user.
 *
 * This is much more likely to happen on staff user sessions because of the hijack feature.
 *
 * See: https://sendcloud.atlassian.net/browse/SC-11757
 */
export function pruneStorageOfOtherUsers(userId: number, isHijacked: boolean) {
  if (userId === undefined) {
    return
  }

  Object.keys(localStorage)
    .filter(key => key.match(new RegExp(STORAGE_PREFIX)) && key !== `${STORAGE_PREFIX}${userId}`)
    .forEach((key) => {
      try {
        if (isHijacked) {
          // If a staff member hijacks a user, delete other users' data to prevent quota exceeded errors
          localStorage.removeItem(key)
        } else {
          const userId = parseInt(key.split(STORAGE_PREFIX)[1])
          const variables = getUserStorage(userId)
          // Keeping PUBLIC_STORAGE_KEYS for other accounts and removing the rest
          Object.keys(variables).forEach((storageKey) => {
            if (!Object.values(PUBLIC_STORAGE_KEYS).includes(storageKey)) {
              removeVariable(userId, storageKey)
            }
          })
        }
      } catch { }
    })
}
