/** @typedef {import('@/types/models').User} User */

import store from '@/common/stores/store'
import EventBus from '@/common/utils/event-bus'
import { CORE_DISPLAY_PAYMENT_WALL } from '@/common/stores/core/action.types'
import AdyenPaymentService from '@/features/payment-wall/services/adyen-payment.service'

export const TYPES = {
  NONE: undefined,
  INITIAL: 'initial',
  OVERDUE: 'overdue',
}

/**
 * Gets the payment wall type based on current user payment status or selected shipping methods for announcement
 * @param {User} user
 * @param {boolean} isOnlyAnnouncingWithUnstampedLetters
 * @returns {string | undefined}
 */
function getWallType(user, isOnlyAnnouncingWithUnstampedLetters) {
  // The frontend currently has no way of knowing if the user has completed
  // the initial payment step. Instead, it relies on the field “allowed_to_announce”.
  // This is inaccurate because there are several scenarios of how a user can
  // get their account into a state where they’re no longer allowed to announce
  // while at the same time fulfilling the initial payment criteria.
  // The following lines workaround this shortcoming for the special case of a user account
  // being set to “on hold” via the admin.
  if (user.allowed_to_announce === false && user.is_account_on_hold) {
    return TYPES.NONE
  }

  // Once [SC-21810][1] is done,
  // we can rely on `user.has_completed_initial_payment` instead of `user.allowed_to_announce`.
  // Then, the previous check can be removed.
  // [1] https://sendcloud.atlassian.net/browse/SC-21810
  if (user.allowed_to_announce || (isOnlyAnnouncingWithUnstampedLetters && !user.has_overdue_invoices)) {
    // Users (without overdue invoices) trying to request unstamped
    // letters should not be disturbed. This is also true for users allowed
    // to announce.
    // No payment wall is required; we're good to go =)
    return TYPES.NONE
  } else if (user.has_overdue_invoices) {
    return TYPES.OVERDUE
  } else {
    return TYPES.INITIAL
  }
}

/**
 * Check if we should display the payment wall or not based on the user
 * financial health and/or type of shipments provided.
 *
 * Example:
 * <code>
 * PaymentWall.show(isOnlyAnnouncingWithUnstampedLetters)
 *     .then((result) => console.log(result.wallType, result.createLabels, result.announceToCarrier))
 *     .catch ((wallType) => console.log(wallType + ' dismissed!'))
 * </code>
 *
 * @param  {boolean} [isOnlyAnnouncingWithUnstampedLetters] - whether the shipping method for all
 * the requested shipments is unstamped letter
 * @return {Promise} Rejected with the dialog type if it's dismissed.
 *                   Resolved with an object containing the wall type,
 *
 */
function show(isOnlyAnnouncingWithUnstampedLetters) {
  const user = store.getters.user
  return new Promise((resolve, reject) => {
    const wallType = getWallType(user, isOnlyAnnouncingWithUnstampedLetters)
    if (wallType === TYPES.NONE) {
      // Users (without overdue invoices) trying to request unstamped
      // letters should not be disturbed. This is also true for users allowed
      // to announce.
      // No payment wall is required; we're good to go =)
      return resolve({
        wallType: TYPES.NONE,
        createLabels: true,
        announceToCarrier: true,
      })
    }
    EventBus.$once('payment-wall-confirmed', (wallType) => {
      return resolve({
        wallType,
        createLabels: wallType !== TYPES.OVERDUE,
        announceToCarrier: false,
      })
    })
    EventBus.$once('payment-wall-dismissed', (wallType) => {
      return reject(new Error(wallType))
    })
    store.dispatch(CORE_DISPLAY_PAYMENT_WALL, wallType)
  })
}

/**
 * The Adyen payment modal is shown to the user.
 */
async function doPayment() {
  await AdyenPaymentService.show(AdyenPaymentService.PAYMENT_INVOICE_REF_TYPE.INITIAL)
}

export default {
  show,
  doPayment,
  TYPES,
}
