import { parse } from '@/common/data-parsers'
import type { Parser } from '@/common/data-parsers/types/parsers.types'
import { logError, showFullscreenErrorOverlay } from '@/common/utils/errors/error-handlers'

export const strictParse = <T>(data: unknown,
  parser: Parser<T>,
  inContextOf: string, { isEnabledInProd }: { isEnabledInProd: boolean }) => {
  // we will remove this later
  if (!isEnabledInProd && process.env.NODE_ENV !== 'development') {
    return data as T
  }

  return parse(data, parser, inContextOf, (errorMsg) => {
    const error = new Error(errorMsg)
    if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
      console.error(errorMsg)
      showFullscreenErrorOverlay(error)
      throw error
    }
    logError(error)
  })
}
