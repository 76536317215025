/** @typedef {import('@/features/subscription/types/subscriptions.types').BasicPlanInfo} BasicPlanInfo */
/** @typedef {import('@/features/subscription/types/subscriptions.types').ShipmentCount} ShipmentCount */
/** @typedef {import('@/features/subscription/types/subscriptions.types').ShipmentCountRevenueService
 * } ShipmentCountRevenueService */
/** @typedef {import('@/features/subscription/types/subscriptions.types')
 * .SubscriptionsPromotions} SubscriptionsPromotions */
/** @typedef {import('@/features/subscription/types/subscriptions.types').SubscriptionPlan} SubscriptionPlan */
/** @typedef {import('@/features/subscription/types/subscriptions.types').SubscriptionsList} SubscriptionsList */
/** @typedef {import('@/features/subscription/types/subscriptions.types').UserSubscription} UserSubscription */

import { subscriptionsStaticData } from '@/features/subscription/constants'
import axios from '@/common/utils/axios'
import store from '@/common/stores/store'
import { getAbsoluteBackendURL } from '@/common/utils/backend'

const axiosRevenueClient = axios.create({
  baseURL: getAbsoluteBackendURL(import.meta.env.VUE_APP_REVENUE_SERVICE_BASE_URL),
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

/**
 * Builds the Revenue Service URL for the route passed.
 * @param {string} route 'i.e /plans'.
 * @returns {string} a URL pointing to the Revenue service.
 */
function buildRevenueServiceUrl(route) {
  const baseUrl = getAbsoluteBackendURL(import.meta.env.VUE_APP_REVENUE_SERVICE_BASE_URL)
  return `${baseUrl}${route}`
}

export default {
  _subscriptionsAvailableList: null,
  _subscriptionsList: null,
  _subscriptionsFeatures: null,

  subscriptionsStaticData,

  /**
   * Retrieves the subscriptions and features per subscription group and returns an array of objects that
   * contain the plan features, group, order, and plan name for each group of plans.
   *
   * @returns {Promise<BasicPlanInfo[]>}
   */
  async getBasicPlansInfo() {
    const { ESSENTIAL_SUBSCRIPTIONS, GROUP_ORDER } = subscriptionsStaticData()
    let [plansArray, plansFeatures] = await Promise.all([this.getSubscriptions(), this.getUserSubscriptionFeatures()])
    // When the backend doesn't send the full set of plans, the missing ones need to be appended by the client app
    plansArray = plansArray.data
    plansFeatures = plansFeatures.data
    const hasDynamicPlans = this.hasDynamicPlans(plansArray)
    const userIsOnPricingPilot = store.getters.userIsOnPricingPilot
    const plansSet = hasDynamicPlans || userIsOnPricingPilot ? plansArray : [...ESSENTIAL_SUBSCRIPTIONS, ...plansArray]

    if (this.userIsInChargebeeSubscriptionManagementEnabledBeta()) {
      return plansArray.map((plan) => {
        return {
          features: plansFeatures[plan.group],
          ...plan,
        }
      })
    } else {
      return plansSet
        .reduce((basicPlansInfo, currentPlan) => {
          if (!basicPlansInfo.find(plan => plan.group === currentPlan.group)) {
            basicPlansInfo.push({
              features: plansFeatures[currentPlan.group],
              group: currentPlan.group,
              name: currentPlan.title,
              // When the backend doesn't send the full set of plans, the order needs to be set by the client app
              // It is assumed that when the full set of plans is received, they are also sorted in the right order
              order: hasDynamicPlans ? basicPlansInfo.length + 1 : GROUP_ORDER[currentPlan.group],
            })
          }

          return basicPlansInfo
        }, [])
        .sort((a, b) => a.order - b.order)
    }
  },

  /**
   * Retrieves and caches the list of available subscriptions for user.
   * @returns {Promise<Object|{ data: SubscriptionsList>}}
   */
  async getSubscriptions() {
    if (this._subscriptionsList) {
      return this._subscriptionsList
    }
    if (this.userIsInChargebeeSubscriptionManagementEnabledBeta()) {
      const { data } = await axiosRevenueClient.get(buildRevenueServiceUrl('/plans'))
      this._subscriptionsList = data
      return data
    }
    return axios.get('/xhr/subscriptions?new_plans=1').then((res) => {
      this._subscriptionsList = res
      return res
    })
  },

  /**
   * Retrieves and caches the list of all available new generation subscriptions.
   * @returns {Promise<{ data: SubscriptionPlan[]}>}
   */
  getAvailableSubscriptionsList() {
    if (this._subscriptionsAvailableList) {
      return this._subscriptionsAvailableList
    }
    return axios.get('/xhr/subscriptions-list').then((res) => {
      this._subscriptionsAvailableList = res
      return res
    })
  },

  /**
   * @returns {Promise<{ data: SubscriptionsPromotions }>}
   */
  getSubscriptionPromotions() {
    return axios.get('/xhr/subscriptions/promotions?new_plans=1')
  },

  /**
   * Retrieves and caches the list of subscription features.
   * @returns {Promise<Object>}
   */
  getUserSubscriptionFeatures() {
    if (this._subscriptionsFeatures) {
      return this._subscriptionsFeatures
    }
    return axios.get('/xhr/subscriptions-features').then((res) => {
      this._subscriptionsFeatures = res
      return res
    })
  },

  /**
   * @returns {Promise<{ data: UserSubscription[] }>}
   */
  async getUserSubscriptions() {
    // TODO(Rhian): The usage in code of the getUserSubscriptions method expects a return value with the shape
    // `{ data: UserSubscription[] }`, but if the user is in the beta feature, this is returned as an object i.e.:
    // `UserSubscription`. This needs checking.
    if (this.userIsInChargebeeSubscriptionManagementEnabledBeta()) {
      const { data: { data } } = await axiosRevenueClient.get(buildRevenueServiceUrl('/subscriptions'))
      return data
    }
    return axios.get('/xhr/user-subscriptions')
  },

  /**
   * @returns {Promise<{ data: ShipmentCount }>}
   */
  getShipmentCount() {
    return axios.get('/xhr/user-subscriptions/shipment-count')
  },

  /**
   * @param {String} subscriptionId
   * @returns {Promise<{ data: ShipmentCountRevenueService }>}
   */
  async getRevenueServiceShipmentCount(subscriptionId) {
    const { data } = await axiosRevenueClient.get(buildRevenueServiceUrl(`/subscriptions/${subscriptionId}/usages`))
    return data
  },

  async createUserSubscription({ code, subscriptionId }) {
    if (this.userIsInChargebeeSubscriptionManagementEnabledBeta()) {
      await axiosRevenueClient.patch(buildRevenueServiceUrl(`/subscriptions/${subscriptionId}`), { code })
      return
    }
    return axios.post('/xhr/user-subscriptions', { code })
  },

  switchUserSubscription(payload) {
    return axios.post('/xhr/user-subscriptions/switch', payload)
  },

  /**
   * @param {String} subscriptionId
   * @param {String} newPlanCode
   * @returns {Promise<{data: SubscriptionsList}>}
   */
  async switchUserSubscriptionWithRevenueService(subscriptionId, newPlanCode) {
    const { data: { data } } = await axiosRevenueClient.patch(buildRevenueServiceUrl(`/subscriptions/${subscriptionId}`), { code: newPlanCode })
    return data
  },

  deactivateUserSubscription(payload) {
    return axios.post('/xhr/user-subscriptions/deactivate', payload)
  },

  requestToBeContacted() {
    return axios.post('/xhr/create-contact-me-task')
  },

  /**
   * @param {BasicPlanInfo[]} basicPlansInfo
   * @param {String} featureId
   * @param {Number|String} [featureValue]
   * @returns {String}
   */
  minimumPlanForFeature(basicPlansInfo, featureId, featureValue) {
    const plan = basicPlansInfo.find((currentPlanInfo) => {
      if (
        featureValue &&
        currentPlanInfo?.features.hasOwnProperty(featureId) &&
        Array.isArray(currentPlanInfo.features[featureId])
      ) {
        return currentPlanInfo.features[featureId].includes(featureValue)
      } else if (
        featureValue &&
        currentPlanInfo?.features.hasOwnProperty(featureId) &&
        typeof currentPlanInfo.features[featureId] === 'number'
      ) {
        return currentPlanInfo?.features[featureId] >= featureValue || currentPlanInfo?.features[featureId] === -1
      }
      return currentPlanInfo?.features[featureId]
    })

    if (plan?.name) {
      return plan.name
    }
    throw new Error('No plan was found for the given inputs')
  },

  /**
   * Returns the name of the plan from which a feature becomes available to users.
   *
   * @param {Object[]} plansArray - The array of subscriptions supplied by the backend
   * @param {String} [expectedPlanGroup] - The code of the group of plans expected to be
   * found in the plans array. Defaults to 'essential'
   * @returns {Boolean}
   */
  hasDynamicPlans(plansArray, expectedPlanGroup = 'essential') {
    const essentialPlan = plansArray.find(plan => plan.group === expectedPlanGroup)
    return Boolean(essentialPlan)
  },

  /**
   * Returns the translated text of a given description string.
   *
   * @param {String} descriptionText - The description text to be translated
   * @returns {String}
   */
  translateDescriptionText(descriptionText) {
    // TODO: Find a proper solution for translating keys stored in the database
    const { PRICING_PLANS_2022_DISPLAY_DATA } = subscriptionsStaticData()
    const descriptionItem = Object.values(PRICING_PLANS_2022_DISPLAY_DATA.descriptions).find(descriptionItem =>
      descriptionItem.hasOwnProperty(descriptionText),
    )
    return descriptionItem ? descriptionItem[descriptionText] : ''
  },

  /**
   * Returns the translated text of a given feature text
   *
   * @param {String} featureText - The feature text to be translated
   * @returns {String}
   */
  translateFeatureText(featureText) {
    // TODO: Find a proper solution for translating keys stored in the database
    const { PRICING_PLANS_2022_DISPLAY_DATA } = subscriptionsStaticData()
    const featureItem = Object.values(PRICING_PLANS_2022_DISPLAY_DATA.descriptions).find(featureItem =>
      featureItem.hasOwnProperty(featureText),
    )
    return featureItem ? featureItem[featureText] : ''
  },

  /**
   * Returns a boolean indicating if the user is in the chargebee beta
   *
   * @returns {Boolean}
   */
  userIsInChargebeeSubscriptionManagementEnabledBeta() {
    return store.getters.hasBetaFeature('chargebee_subscription_management_enabled')
  },
}
