import i18n from '@/application/i18n/i18n'
import type { RouteRecordRaw } from 'vue-router'
import { useNewNavigation } from '@/common/utils/get-navigation-preference'

export const SETTINGS_ACCOUNT_ROUTE = 'settings.account'

export default {
  name: SETTINGS_ACCOUNT_ROUTE,
  path: useNewNavigation ? '/account/profile' : 'account',
  component: () => import('@/features/account/pages/AccountSettingsPage.vue'),
  meta: {
    pageTitle: () => i18n.t('Account'),
    // This is one route view offering both account deletion and data
    // export. So, if either one of those permissions is granted the user can
    // enter the route.
    permissions: ['delete_account', 'export_data'],
    parent: 'account-profile',
  },
  alias: ['/settings/account', '/account/profile'],
} satisfies RouteRecordRaw
