import axios from '@/common/utils/axios'
import type { Integration, IntegrationKeys, IntegrationSettingsField } from '@/types/models'
import type {
  IntegrationLog,
  IntegrationShopOrderStatusesData,
  IntegrationShopOrderStatusDefaultMappingData,
  IntegrationShopOrderStatusMappingData,
  IntegrationSupportData,
  IntegrationShopOrderStatusMappingPost,
} from '@/features/integrations/types/integrations.types'
import store from '@/common/stores/store'

export default {
  async create(integration: Integration): Promise<Integration> {
    const { data } = await axios.post('/orders/xhr/integrations', integration)
    return data
  },

  // TODO update to use new endpoint
  // https://sendcloud.atlassian.net/browse/SC-58095
  async delete(integrationId: number): Promise<void> {
    await axios.delete(`/orders/xhr/integration/${integrationId}`)
  },

  async find(integrationId: number): Promise<Integration> {
    const { data } = await axios.get(`/orders/xhr/integrations/${integrationId}`)
    return data
  },

  // TODO update to use new endpoint
  // https://sendcloud.atlassian.net/browse/SC-58095
  async findAll(): Promise<Integration[]> {
    const { data } = await axios.get('/orders/xhr/integration')
    return data
  },

  async getFullIntegrationsList(): Promise<Integration[]> {
    const { data } = await axios.get('/orders/xhr/integrationtype/') // The trailing slash prevents a 301 redirect
    return data
  },

  /* eslint-disable @stylistic/max-len */
  async getIntegrationSystemSettings(systemCode: string, isEditing: boolean = false): Promise<IntegrationSettingsField[]> {
    const { data } = await axios.get(`/orders/xhr/settings/${systemCode}?is_editing=${isEditing}`)
    return data
  },

  // TODO update to use new endpoint
  // https://sendcloud.atlassian.net/browse/SC-58095
  async initializeTwoStepConfiguration(integrationId: number): Promise<{ redirect_url?: string }> {
    const { data } = await axios.post(`/orders/xhr/integration/${integrationId}/initialize`)
    return data
  },

  // TODO update to use new endpoint
  // https://sendcloud.atlassian.net/browse/SC-58095
  async fetchLogsForIntegration(integrationId: number): Promise<IntegrationLog[]> {
    return await axios.get(`/orders/xhr/integration/${integrationId}/logs`)
  },

  // TODO update to use new endpoint
  // https://sendcloud.atlassian.net/browse/SC-58095
  async fetchAllLogs(): Promise<IntegrationLog[]> {
    return await axios.get('/orders/xhr/integration/logs')
  },

  // TODO update to use new endpoint
  // https://sendcloud.atlassian.net/browse/SC-58095
  async testWebhook(integration: Integration): Promise<boolean> {
    const { data } = await axios.post('/orders/xhr/integration/test_webhook', integration)
    return data
  },

  async update(integration: Integration): Promise<Integration> {
    const { data } = await axios.put(`/orders/xhr/integrations/${integration.id}`, integration)
    return data
  },

  async regenerateApiKeys(integrationId: number): Promise<IntegrationKeys> {
    const { data } = await axios.post(`/orders/xhr/integrations/${integrationId}/regenerate-api-keys`)
    return data
  },

  async fetchSupportData(integrationType: string): Promise<IntegrationSupportData> {
    const { data } = await axios.get('/orders/xhr/integrations/type/support-info/', { params: { integration_type: integrationType } })
    return data.data[0]
  },

  /**
   * Fetch a map of available shop order statuses and Sendcloud's internal status category for the integration.
   * @link https://gitlab.com/sendcloud/platform/api/sendcloud-public-api/-/blob/v3/v3/schemas/integrations/openapi.yaml?ref_type=heads
   */
  async getShopOrderStatusesMapping(integrationId: number): Promise<IntegrationShopOrderStatusMappingData> {
    const { data } = await axios.get(`/api/v3/shop-order-statuses/mapping`, { params: { integration_id: integrationId } })
    return data
  },

  /**
   * Get the list of statuses for the integration. If the user hasn't picked a language, they will receive the status in the default language (en-gb).
   * If 'en-gb' is not present, the user receives the status in the first language of the alphabetically ordered list.
   * @link https://gitlab.com/sendcloud/platform/api/sendcloud-public-api/-/blob/v3/v3/schemas/integrations/openapi.yaml?ref_type=heads
   */
  async getShopOrderStatuses(integrationId: number): Promise<IntegrationShopOrderStatusesData> {
    const { data } = await axios.get(`/api/v3/shop-order-statuses`, { params: { integration_id: integrationId, language: store.getters.userLanguage, show_deleted: true } })
    return data
  },

  /**
   * This endpoint allows you to access the default statuses mapping for a specific integration
   * @link https://sendcloud.gitlab.io/platform/api/sendcloud-xhr-api/integrations.html
   */
  async getShopOrderStatusesDefaultMapping(integrationType: string): Promise<IntegrationShopOrderStatusDefaultMappingData> {
    const { data } = await axios.get(`/orders/xhr/integrations/type/${integrationType}/shop-order-statuses/mapping/default/`)
    return data
  },

  /**
   * This endpoint allows you to save the statuses mapping for a specific integration
   * @link https://sendcloud.gitlab.io/platform/api/sendcloud-xhr-api/integrations.html
   */
  async saveShopOrderStatusesDefaultMapping(statusMapping: IntegrationShopOrderStatusMappingPost): Promise<void> {
    await axios.post(`/api/v3/shop-order-statuses/mapping`, statusMapping)
  },
}
