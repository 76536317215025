<template>
  <section>
    <AppPaymentModal
      v-if="showPaymentModal"
      data-test="app-payment-modal"
      @on-payment-result="openPaymentResult"
    />
    <AppPaymentResultModal
      v-else-if="showPaymentResultModal"
      :result-info="paymentResultInfo"
      data-test="app-payment-result-modal"
      @close="closePaymentResult"
    />
    <AppPaymentWall v-else-if="showPaymentWall" />
  </section>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import AppPaymentResultModal from '@/features/payment-wall/components/AppPaymentResultModal.vue'

import { TYPES } from '@/features/payment-wall/services/payment-wall.service.js'

export default defineComponent({
  name: 'AppPaymentWallWrapper',

  components: {
    AppPaymentModal: defineAsyncComponent(() => import('@/features/payment-wall/components/AppPaymentModal.vue')),
    AppPaymentWall: defineAsyncComponent(() => import('@/features/payment-wall/components/AppPaymentWall.vue')),
    AppPaymentResultModal,
  },

  data() {
    return {
      paymentResultInfo: undefined,
      isPaymentRedirect: false as boolean | undefined,
    }
  },

  computed: {
    ...mapGetters(['paymentInvoiceId', 'paymentWallType', 'hasBetaFeature', 'user']),

    showPaymentModal(): boolean {
      // The payment modal is displayed when there is a payment invoice set
      // and there is no result from the payment process, or the site is being
      // loaded as a result of the redirection from the payment service.

      // The invoice ID must not be cleared because it could be necessary from
      // the payment result modal if the user chooses to use the old implementation.

      return (this.isPaymentRedirect || (this.paymentInvoiceId !== undefined && this.paymentResultInfo === undefined))
    },

    showPaymentResultModal(): boolean {
      return this.paymentResultInfo !== undefined
    },

    showPaymentWall(): boolean {
      return this.paymentWallType &&
        Object.values(TYPES).includes(this.paymentWallType)
    },

  },

  created() {
    if (!window.location.search) {
      this.isPaymentRedirect = false
    } else {
      const searchParams = new URLSearchParams(window.location.search)
      this.isPaymentRedirect = searchParams.has('redirectResult') && searchParams.has('sessionId')
    }
  },

  methods: {
    /**
     * Displays the payment result modal by assigning a result type
     */
    openPaymentResult(result: any) {
      this.isPaymentRedirect = undefined
      this.paymentResultInfo = result
    },

    /**
     * Hides the payment result modal by clearing the value of the result type
     */
    closePaymentResult() {
      this.paymentResultInfo = undefined
    },
  },
})
</script>
