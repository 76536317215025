import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar.js'

const DEFAULT_LOCALE = 'en'

export default {
  timeZoneName: null,

  /**
   * @param {string} timeZoneName according to the tz database canonical timezones, for example 'Europe/Amsterdam'
   */
  setTimeZoneName(timeZoneName) {
    try {
      new Date().toLocaleString('en-US', { timeZone: timeZoneName })
      this.timeZoneName = timeZoneName
    } catch { }
  },

  /**
   * @private
   * @param {Date|dayjs.Dayjs|string|number} [date=new Date()] date any valid date constructor or a dayjs date
   * @param {boolean} isIgnoringUserTimeZone ignores user time zone when this value is set to true
   * @returns {dayjs.Dayjs} returns dayjs object
   */
  getDayjsDateWithTimeZoneOffset(date = new Date(), isIgnoringUserTimeZone = false) {
    // if it's a dayjs object return it as it is since applying the timezone twice
    // would result in a wrong date
    if (date instanceof dayjs) {
      return date
    }

    const theDate = date instanceof Date ? date : new Date(date)

    if (Number.isNaN(theDate.getTime())) {
      // new Date(NaN) doesn't not raise any error but stores "Invalid Date" internally
      throw new Error(`Invalid date ${date}`)
    }

    if (this.timeZoneName && !isIgnoringUserTimeZone) {
      return dayjs(theDate.toLocaleString('en-US', { timeZone: this.timeZoneName }))
    }
    return dayjs(theDate.toLocaleString('en-US'))
  },

  /**
   * @param {boolean} [isIgnoringUserTimeZone=false] ignores user time zone when this value is set to true
   */
  now(isIgnoringUserTimeZone = false) {
    return this.getDayjsDateWithTimeZoneOffset(null, isIgnoringUserTimeZone)
  },

  /**
   * @param {*} date any valid date, same as what JavaScript's Date() accepts or a day.js object
   * @param {boolean} [isIgnoringUserTimeZone=false] ignores user time zone when this value is set to true
   * @returns {object} returns day.js object
   */
  fromDate(date, isIgnoringUserTimeZone = false) {
    return this.getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone)
  },

  /**
   * @param {number} amount amount of time to add
   * @param {string} unit unit to add
   * here's a list of all available units https://day.js.org/docs/en/manipulate/add#list-of-all-available-units
   * @param {*} date any valid date, same as what JavaScript's Date() accepts or a day.js object
   * @param {boolean} [isIgnoringUserTimeZone=false] ignores user time zone when this value is set to true
   * @returns {object} returns day.js object
   */
  add(amount, unit, date, isIgnoringUserTimeZone = false) {
    return this.getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).add(amount, unit)
  },

  /**
   * @param {*} date any valid date, same as what JavaScript's Date() accepts or a day.js object
   * @param {boolean} [isIgnoringUserTimeZone=false] ignores user time zone when this value is set to true
   * @returns {string}
   */
  calendar(date, isIgnoringUserTimeZone = false) {
    return this.getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).calendar()
  },

  /**
   * @param {string} unit a valid unit to end at, see all units here https://day.js.org/docs/en/manipulate/end-of
   * @param {*} any valid date, same as what JavaScript's Date() accepts or a day.js object
   * @param {boolean} [isIgnoringUserTimeZone=false] ignores user time zone when this value is set to true
   * @returns {object} returns day.js object
   */
  endOf(unit, date, isIgnoringUserTimeZone = false) {
    return this.getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).endOf(unit)
  },

  /**
   * @param {string} format Day.js format string, see all options here https://day.js.org/docs/en/display/format
   * @param {*} date any valid date, same as what JavaScript's Date() accepts or a day.js object
   * @param {boolean} [isIgnoringUserTimeZone=false] ignores user time zone when this value is set to true
   * @returns {string}
   */
  format(format, date, isIgnoringUserTimeZone = false) {
    return this.getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).format(format)
  },

  /**
   * @param {*} date any valid date, same as what JavaScript's Date() accepts or a day.js object
   * @param {boolean} hideSuffix removes the suffix (e.g. “4 days” instead of “4 days ago”)
   * @param {boolean} [isIgnoringUserTimeZone=false] ignores user time zone when this value is set to true
   * @returns {string}
   */
  fromNow(date, hideSuffix = false, isIgnoringUserTimeZone = false) {
    return this.getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).fromNow(hideSuffix)
  },

  /**
   * @param {string} languageCode a string matching locale files defined by dayjs (all lowercase).
   * @see {@link https://github.com/iamkun/dayjs/tree/dev/src/locale|Dayjs locales list}
   */
  init(dayjsLocale) {
    dayjs.extend(localizedFormat)
    dayjs.extend(relativeTime)
    dayjs.extend(calendar)

    if (dayjsLocale !== DEFAULT_LOCALE) {
      dayjs.locale(dayjsLocale)
    }
  },

  /**
   * @param {string} unit a valid unit to start from, see all units here https://day.js.org/docs/en/manipulate/start-of
   * @param {*} any valid date, same as what JavaScript's Date() accepts or a day.js object
   * @param {boolean} [isIgnoringUserTimeZone=false] ignores user time zone when this value is set to true
   * @returns {object} returns day.js object
   */
  startOf(unit, date, isIgnoringUserTimeZone = false) {
    return this.getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).startOf(unit)
  },

  /**
   * @param {number} amount amount of time to subtract
   * @param {string} unit unit to subtract
   * here's a list of all available units https://day.js.org/docs/en/manipulate/add#list-of-all-available-units
   * @param {*} date any valid date, same as what JavaScript's Date() accepts or a day.js object
   * @param {boolean} [isIgnoringUserTimeZone=false] ignores user time zone when this value is set to true
   * @returns {object} returns day.js object
   */
  subtract(amount, unit, date = new Date(), isIgnoringUserTimeZone = false) {
    return this.getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).subtract(amount, unit)
  },

  /**
   * @param {Date|dayjs.Dayjs|string|number} date any valid date constructor or a dayjs date
   * @returns {Date} returns JavaScript Date object
   */
  toNativeJsDate(textOrDate) {
    let jsDate

    if (textOrDate instanceof Date) {
      jsDate = textOrDate
    } else if (textOrDate instanceof dayjs) {
      jsDate = textOrDate.toDate()
    } else {
      jsDate = new Date(textOrDate)
    }

    if (Number.isNaN(jsDate.getTime())) {
      // new Date(NaN) doesn't raise any errors but stores "Invalid Date" internally
      throw new Error(`Invalid date ${textOrDate}`)
    } else {
      return jsDate
    }
  },

  /**
   * @param {*} date any valid date, same as what JavaScript's Date() accepts or a day.js object
   * @param {boolean} [isIgnoringUserTimeZone=false] ignores user time zone when this value is set to true
   * @returns {string}
   */
  toNow(date, isIgnoringUserTimeZone = false) {
    return this.getDayjsDateWithTimeZoneOffset(date, isIgnoringUserTimeZone).toNow()
  },

  /**
   * Check if the dates passed fall on the same day.
   * @param {Date|dayjs.Dayjs} dateA any valid date constructor or a dayjs date
   * @param {Date|dayjs.Dayjs} dateB any valid date constructor or a dayjs date
   * @returns {boolean} true when it's the same day, false otherwise.
   */
  isSameDay(dateA, dateB) {
    const dayJsDateA = this.getDayjsDateWithTimeZoneOffset(dateA)
    const dayJsDateB = this.getDayjsDateWithTimeZoneOffset(dateB)
    return dayJsDateA.isSame(dayJsDateB, 'day')
  },
}
