import axios from '@/common/utils/axios'
import { getAbsoluteBackendURL } from '@/common/utils/backend'
import isExperimentsEnabled from '@/features/experiments/utils/is-experiments-enabled'
import ExperimentsAnalytics from '@/features/experiments/utils/experiments.analytics'
import * as Sentry from '@sentry/vue'
import { shouldReportError } from '@/common/utils/errors/should-report-error'
import { type ExperimentResponse } from '@/features/experiments/types/experiment.types'

/**
 * Builds a URL to fetch the experiment variant based on the provided experiment ID.
 */
function buildUrlExperiment(experimentId: string): string {
  // removing empty spaces -- avoiding blank spaces as identifiers
  experimentId = experimentId.split(/\s+/).join('')

  const baseUrl = getAbsoluteBackendURL(import.meta.env.VUE_APP_EXPERIMENTS_SERVICE_BASE_URL)
  return `${baseUrl}/${experimentId}`
}

export async function fetchExperimentVariant(experimentId: string): Promise<string | null> {
  if (isExperimentsEnabled() !== 'yes' || !experimentId) {
    return null
  }
  let timeout: number = 3000
  try {
    const url = buildUrlExperiment(experimentId)

    // timeout different from the rest to avoid block the application
    if (experimentId === 'information_architecture') {
      timeout = 500
    }
    const response: ExperimentResponse = await axios.get(url, {
      // `timeout` specifies the number of milliseconds before the request times out.
      // If the request takes longer than `timeout`, the request will be aborted.
      timeout: timeout,
    })
    if (response.data.experiment?.variant_id) {
      ExperimentsAnalytics.userJoinedExperiment(experimentId, response.data.experiment.variant_id)
      return response.data.experiment.variant_id
    } else {
      return null
    }
  } catch (error) {
    if (shouldReportError(error)) {
      Sentry.captureException(error)
    }
    return null
  }
}
