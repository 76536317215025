<template>
  <li class="menu-item">
    <!-- Necessary in order to link to absolute URLs in the top menu. -->
    <a
      v-if="typeof href === 'string' && href.startsWith('http')"
      v-tooltip.hover.focus="tooltip ? tooltipTitle : false"
      :class="{ 'menu-item__link--active': linkIsActive, 'menu-item__link--icon': icon , 'menu-item__link--external-url': externalUrl }"
      :data-test="id"
      :data-smoke-test="id.replace('smoke-test-', '')"
      :href="href"
      :target="target"
      :rel="rel"
      :data-appcues="dataAppcues"
      class="menu-item__link"
      @click="menuItemClicked"
    >
      <slot />
      <span
        v-if="showWarning"
        class="menu-item__warning"
      />
    </a>

    <button
      v-else-if="isRouteSameAsCurrentRouteAndEventNameProvided"
      :id="id"
      v-tooltip.hover.focus="tooltip ? tooltipTitle : false"
      :class="{ 'menu-item__link--active': linkIsActive, 'menu-item__link--icon': icon }"
      :data-test="id"
      :data-smoke-test="id.replace('smoke-test-', '')"
      :to="href"
      :data-appcues="dataAppcues"
      class="menu-item__link"
      @click="emitEvent(alternativeEventName)"
    >
      <slot />
      <span
        v-if="showWarning"
        class="menu-item__warning"
      />
    </button>

    <router-link
      v-else
      :id="id"
      v-tooltip.hover.focus="tooltip ? tooltipTitle : false"
      :class="{ 'menu-item__link--active': linkIsActive, 'menu-item__link--icon': icon }"
      :data-test="id"
      :data-smoke-test="id.replace('smoke-test-', '')"
      :to="href"
      :data-appcues="dataAppcues"
      class="menu-item__link"
      @click="menuItemClicked"
    >
      <slot />
      <span
        v-if="showWarning"
        class="menu-item__warning"
      />
    </router-link>
  </li>
</template>

<script>
import EventBus from '@/common/utils/event-bus'
import tooltip from '@/common/utils/tooltip.directive'
import OldNavigationAnalytics from '@/application/utils/old-navigation.analytics'

export default {
  name: 'AppTopMenuItem',

  directives: {
    tooltip,
  },

  props: {
    href: {
      type: [String, Object],
      required: true,
      validator(href) {
        if (typeof href === 'string') {
          return href.length > 0
        } else {
          return 'name' in href
        }
      },
    },

    modulePath: {
      type: String,
      required: true,
    },

    alternativeEventName: {
      // When this is in TS make sure to use `alternativeEventName: BusEventWithoutParams` (from EventBus)
      type: String,
      required: false,
      default: '',
    },

    currentRouteName: {
      type: String,
      required: false,
      default: '',
    },

    icon: {
      type: Boolean,
      required: false,
      default: false,
    },

    externalUrl: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,
      default: '',
    },

    target: {
      type: String,
      required: false,
      default: '',
    },

    rel: {
      type: String,
      required: false,
      default: '',
    },

    tooltip: {
      type: Boolean,
      required: false,
      default: false,
    },

    tooltipTitle: {
      type: String,
      required: false,
      default: '',
    },

    showWarning: {
      type: Boolean,
      required: false,
      default: false,
    },

    dataAppcues: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    linkIsActive() {
      if (this.$route && this.$route.path) {
        const path = this.$route.path.replace(/^\/+/, '')
        return path.startsWith(this.modulePath)
      }

      return false
    },

    isRouteSameAsCurrentRouteAndEventNameProvided() {
      return this.href.name === this.currentRouteName && Boolean(this.alternativeEventName)
    },
  },

  methods: {
    emitEvent(event) {
      if (!event) {
        return
      }

      EventBus.$emit(event)
    },

    menuItemClicked() {
      OldNavigationAnalytics.trackNavMenuItemClick(this.dataAppcues)
    },
  },
}
</script>

<style lang="scss">
.menu-item__link {
  color: $sc-dark-blue;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: flex-start;
  padding: 15px 10px;
  position: relative;
  border: none;
  background-color: transparent;
  width: 100%;
  text-decoration: none;

  &::before {
    background-color: #f4faff;
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: -1;

    @include breakpoint-min-width(large) {
      height: 92%;
    }
  }

  &::after {
    background-color: $sc-informative;
    bottom: 0;
    content: '';
    height: 5px;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  @include breakpoint-min-width(large) {
    align-items: center;
    height: 100%;
    padding: 0 15px;

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &:focus {
      &::after {
        background-color: $sc-focus;
        opacity: 1;
      }
    }
  }

  @include breakpoint-min-width(extra-large) {
    padding: 0 20px;
  }
}

.menu-item__link--external-url {
  color: $sc-link-blue;
}

.menu-item__link--icon {
  color: $sc-link-blue;
}

.menu-item__warning {
  display: none;
  background-color: $sc-danger;
  border-radius: 50%;
  height: 8px;
  min-width: 8px;

  @include breakpoint-min-width(large) {
    display: block;
    position: absolute;
    right: 12px;
    top: 23px;
  }
}

.menu-item__link.menu-item__link--active {
  &::before {
    opacity: 1;
  }

  @include breakpoint-min-width(large) {
    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
      background-color: $sc-informative;
    }

    &:hover,
    &:focus {
      &::before {
        opacity: 1;
      }
    }
  }
}
</style>
