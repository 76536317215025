import type { RouteRecordRaw } from 'vue-router'
import i18n from '@/application/i18n/i18n'
import { useNewNavigation } from '@/common/utils/get-navigation-preference'

export const SETTINGS_NOTIFY_TRACKING_WIDGET_ROUTE = 'notify.tracking-portal'

export default {
  name: SETTINGS_NOTIFY_TRACKING_WIDGET_ROUTE,
  path: useNewNavigation ? '/tracking/widget' : 'widget',
  component: () => import('@/features/tracking-widget/pages/TrackingWidgetPage.vue'),
  meta: {
    pageTitle: () => i18n.t('Tracking widget'),
    permissions: ['configure_tracking'],
    parent: 'tracking-widget',
  },
  alias: ['/settings/track-and-trace/widget'],
} satisfies RouteRecordRaw
