import axios from '@/common/utils/axios'

export default {
  findAll() {
    return axios.get('/xhr/return-portal')
  },

  create(rawPayload) {
    // the `return_address` key is mapped to `chosen_return_address` in the PUT call
    const payload = { ...rawPayload }
    payload.chosen_return_address = payload.return_address
    delete payload.return_address
    return axios.post(`/xhr/return-portal`, payload)
  },

  update(id, rawPayload) {
    // the `return_address` key is mapped to `chosen_return_address` in the PUT call
    const payload = { ...rawPayload }
    payload.chosen_return_address = payload.return_address
    delete payload.return_address
    return axios.patch(`/xhr/return-portal/${id}`, payload)
  },

  connectPaymentProvider(id, payload) {
    return axios.post(`/xhr/return-portal/${id}/payments`, payload)
  },

  deletePaymentProvider(id) {
    return axios.delete(`/xhr/return-portal/${id}/payments`)
  },
}
