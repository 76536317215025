/** @typedef {import('@/types/api').SenderAddressParameters} SenderAddressParameters */
/** @typedef {import('@/types/models').SenderAddress} SenderAddress */

import axios from '@/common/utils/axios'

export default {
  /**
   * @param {SenderAddress} senderAddress
   * @returns {Promise<{ data: SenderAddress }>}
   */
  create(senderAddress) {
    return axios.post('/xhr/senderaddress', senderAddress)
  },

  /**
   * @param {number} senderAddressId
   * @returns {Promise<{ data: Array<SenderAddress> }>}
   */
  find(senderAddressId) {
    return axios.get(`/xhr/senderaddress/${senderAddressId}`)
  },

  /**
   * @param {SenderAddressParameters} [params]
   * @returns {Promise<{ data: Array<SenderAddress> }>}
   */
  findAll({ countryIso2 } = {}) {
    return axios.get('/xhr/senderaddress', { params: { country: countryIso2 } })
  },

  /**
   * @param {number} senderAddressId
   * @returns {Promise<{ data: undefined }>}
   */
  destroy(senderAddressId) {
    return axios.delete(`/xhr/senderaddress/${senderAddressId}`)
  },

  /**
   * @param {SenderAddress} senderAddress
   * @returns {Promise<{ data: SenderAddress }>}
   */
  update(senderAddress) {
    return axios.patch(`/xhr/senderaddress/${senderAddress.id}`, senderAddress)
  },

  /**
   * @param {number} senderAddressId
   * @returns {Promise<{ data: undefined }>}
   */
  setActive(senderAddressId) {
    return axios.post(`/xhr/senderaddress/${senderAddressId}/activate`)
  },
}
