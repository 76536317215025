import { RouterView, type RouteRecordRaw } from 'vue-router'
import { useNewNavigation } from '@/common/utils/get-navigation-preference'

import { getHelpCenterUrl } from '@/common/services/help-center-url.service'
import i18n from '@/application/i18n/i18n'
import ShippingRulesAnalytics from '@/features/shipping-rules/utils/shipping-rules.analytics'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'

export const SETTINGS_SHIPPING_RULES_ROUTE = 'rules'
export const SETTINGS_SHIPPING_RULES_LIST_ROUTE = 'rules.list'
export const SETTINGS_SHIPPING_RULES_ADD_ROUTE = 'rules.add'
export const SETTINGS_SHIPPING_RULES_EDIT_ROUTE = 'rules.edit'
export const SETTINGS_SHIPPING_RULES_IMPORT_ROUTE = 'rules.import'

export default {
  name: SETTINGS_SHIPPING_RULES_ROUTE,
  path: useNewNavigation ? '/shipping/rules' : 'rules',
  redirect: { name: SETTINGS_SHIPPING_RULES_LIST_ROUTE },
  component: RouterView,
  children: [
    {
      name: SETTINGS_SHIPPING_RULES_LIST_ROUTE,
      path: useNewNavigation ? '/shipping/rules' : 'rules',
      component: () => import('@/features/shipping-rules/pages/ShippingRuleListPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Shipping rules'),
        permissions: ['manage_rules'],
        buttons: [
          {
            id: 'open-help-center',
            title: () => i18n.t('settings.shipping_rules.sub_header'),
            name: 'Help center button',
            href: getHelpCenterUrl('shipping-rules-overview-and-application', 'settings'),
            newTab: true,
            mode: 'borderless',
            icon: faInfoCircle,
            align: 'right',
            customClick: () => ShippingRulesAnalytics.openHelpCenter('Shipping rules list'),
            permissions: ['manage_rules'],
          },
        ],
        parent: 'shipping-rules',
      },
      alias: ['/settings/rules/rules'],
    },
    {
      name: SETTINGS_SHIPPING_RULES_ADD_ROUTE,
      path: useNewNavigation ? '/shipping/rules/add' : 'add',
      component: () => import('@/features/shipping-rules/pages/ShippingRuleDetailsPage.vue'),
      meta: {
        pageTitle: () => i18n.t('New shipping rule'),
        permissions: ['manage_rules'],
        buttons: [
          {
            id: 'open-help-center',
            title: () => i18n.t('settings.shipping_rules.add_shipping_rule.sub_header'),
            name: 'Help center button',
            href: getHelpCenterUrl('how-to-create-shipping-rules', 'settings'),
            newTab: true,
            mode: 'borderless',
            icon: faInfoCircle,
            align: 'right',
            customClick: () => ShippingRulesAnalytics.openHelpCenter('Create shipping rule'),
            permissions: ['manage_rules'],
          },
        ],
        parent: 'shipping-rules',
      },
      alias: ['settings/rules/add'],
    },
    {
      name: SETTINGS_SHIPPING_RULES_IMPORT_ROUTE,
      path: useNewNavigation ? '/shipping/rules/import' : 'import',
      component: () => import('@/features/shipping-rules/pages/ShippingRuleImportPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Shipping rule import'),
        permissions: ['manage_rules'],
        buttons: [
          {
            id: 'open-help-center',
            title: () => i18n.t('settings.shipping_rules.upload_file.sub_header'),
            name: 'Help center button',
            href: getHelpCenterUrl('import-shipping-rules', 'settings'),
            newTab: true,
            mode: 'borderless',
            icon: faInfoCircle,
            align: 'right',
            customClick: () => ShippingRulesAnalytics.openHelpCenter('Shipping rule import'),
            permissions: ['manage_rules'],
          },
        ],
        parent: 'shipping-rules',
      },
      alias: ['settings/rules/import'],
    },
    {
      name: SETTINGS_SHIPPING_RULES_EDIT_ROUTE,
      path: useNewNavigation ? '/shipping/rules/:ruleId' : ':ruleId',
      component: () => import('@/features/shipping-rules/pages/ShippingRuleDetailsPage.vue'),
      props: (route: RouteRecordRaw & { params: { ruleId: string } }) => ({
        ruleId: Number.parseInt(route.params.ruleId),
      }),
      meta: {
        pageTitle: () => i18n.t('Edit shipping rule'),
        permissions: ['manage_rules'],
        buttons: [
          {
            id: 'open-help-center',
            title: () => i18n.t('settings.shipping_rules.add_shipping_rule.sub_header'),
            name: 'Help center button',
            href: getHelpCenterUrl('how-to-create-shipping-rules', 'settings'),
            newTab: true,
            mode: 'borderless',
            icon: faInfoCircle,
            align: 'right',
            customClick: () => ShippingRulesAnalytics.openHelpCenter('Edit shipping rule'),
            permissions: ['manage_rules'],
          },
        ],
        parent: 'shipping-rules',
      },
      alias: ['settings/rules/:ruleId'],
    },
  ],
} satisfies RouteRecordRaw
