import axios from '@/common/utils/axios'

import { DIRECTION_FOLLOW_UP } from '@/app/common/constants'
import type {
  EmailSettingsPayload,
  ParcelStatus,
  SavedTrackingMessageTemplate,
  TrackingMessageCountryPayload,
  TrackingMessageHistory,
  TrackingMessagePreviewPayload,
  TrackingMessagesDirection,
  TrackingMessageSmsLength,
  TrackingMessageTemplate,
  TrackingMessageType,
  TrackingMessageContent,
} from '@/features/tracking-messages/types/tracking-messages.types'
import { strictParse } from '@/common/utils/parsing-utils/strict-parse'
import { TrackingMessagesSettings } from '@/features/tracking-messages/types/tracking-messages-data-parsers'

interface GetPathParams {
  template_name: string
  direction: string
  type: string
}

/**
 * Hack to reduce amount of beunen in that API and be able to refactor it quick when needed
 */
function getPath(params: Partial<GetPathParams>): string {
  if (params.template_name === 'Follow-Up' || params.direction === DIRECTION_FOLLOW_UP) return 'follow-up-email'

  return params.type ? params.type.toLowerCase() : 'email'
}

interface PayloadMutationParams {
  direction: string | null
  status: string | null
}

/**
 * The way Tracking messages was built, we require to send a valid status and direction
 * The ones we created for FOLLOW UP aren't valid ones so we change them back to being valid for the request
 *
 * TODO: Future iterations of Tracking messages will rewrite the way we work with different status types and directions
 */
function payloadMutation(payload: Partial<PayloadMutationParams>): object {
  const newPayload = {
    ...payload,
  }

  if (payload.direction === DIRECTION_FOLLOW_UP) {
    newPayload.status = 'DELIVERED'
    newPayload.direction = 'o'
  }

  return newPayload
}

export async function fetchTrackingMessageSettings(): Promise<TrackingMessagesSettings> {
  const { data } = await axios.get('/xhr/notify/settings')
  return strictParse(data, TrackingMessagesSettings, 'fetchTrackingMessageSettings', { isEnabledInProd: true })
}

export async function updateTrackingMessageSettings(currentSettings: {
  send_emails: boolean
  send_sms: boolean
}): Promise<TrackingMessagesSettings> {
  const { data } = await axios.put('/xhr/notify/settings', currentSettings)
  return strictParse(data, TrackingMessagesSettings, 'updateTrackingMessageSettings', { isEnabledInProd: true })
}

export async function fetchTrackingMessageTemplate(params: {
  brand?: number
  status?: string
  direction: string
  country?: string
  type?: TrackingMessageType
}): Promise<TrackingMessageTemplate> {
  const response = await axios.get(`/xhr/notify/${getPath(params)}`, {
    params: payloadMutation(params),
  })

  if (params.direction === DIRECTION_FOLLOW_UP) {
    // Manually changing the direction and status so that it we can easily generate the follow up tab and statuses
    response.data.message.direction = DIRECTION_FOLLOW_UP
    response.data.message.status = 'FOLLOW_UP'
  }

  return response.data
}

export async function updateTrackingMessageTemplate(
  messageTemplate: TrackingMessageContent,
): Promise<SavedTrackingMessageTemplate> {
  const response = await axios.put(`/xhr/notify/${getPath(messageTemplate)}/${messageTemplate.id}`, messageTemplate)

  return response.data
}

export async function restoreDefaultTrackingMessageTemplate(payload: TrackingMessageContent): Promise<{
  subject: string
  body: string
  button_text?: string
  sms_length?: TrackingMessageSmsLength
}> {
  const response = await axios.post(`/xhr/notify/${getPath(payload)}/restore_default`, payloadMutation(payload))
  return response.data
}

export async function removeTrackingMessageCountry(payload: TrackingMessageCountryPayload): Promise<void> {
  await axios.delete(`/xhr/notify/${getPath(payload)}/${payload.countryIso2}`, {
    params: { brand: payload.brandId },
  })
}

export async function addTrackingMessageCountry(payload: {
  brand: number
  country: string
  type: TrackingMessageType
  direction: keyof TrackingMessagesDirection
}): Promise<void> {
  await axios.post(`/xhr/notify/${getPath(payload)}-country`, payload)
}

export async function fetchTrackingMessagePreview(payload: TrackingMessagePreviewPayload): Promise<string> {
  const response = await axios.post(`/xhr/notify/${getPath(payload)}/preview`, payloadMutation(payload))
  return response.data
}

export async function sendTrackingEmailPreview(payload: TrackingMessagePreviewPayload) {
  const response = await axios.post(`/xhr/notify/${getPath(payload)}/preview?send_email=yes`, payloadMutation(payload))
  return response.data
}

export async function fetchCharacterCount(body: string) {
  const response = await axios.post('/xhr/notify/sms/msg_length_counter', {
    body: body,
  })
  return response.data
}

export async function fetchEmailSettings(brandId: number): Promise<{ show_order_content: boolean }> {
  const response = await axios.get('/xhr/notify/email-settings', {
    params: { brand: brandId },
  })
  return response.data
}

export async function updateEmailSettings(payload: EmailSettingsPayload): Promise<{ show_order_content: boolean }> {
  const response = await axios.put(`/xhr/notify/email-settings?brand=${payload.brandId}`, {
    show_order_content: Boolean(payload.value),
  })
  return response.data
}

async function fetchEmailHistory(parcelId: number): Promise<ParcelStatus[]> {
  return (await axios.get(`/xhr/notify/emailhistory?parcel=${parcelId}`)).data
}

async function fetchSmsHistory(parcelId: number): Promise<ParcelStatus[]> {
  return (await axios.get(`/xhr/notify/smshistory?parcel=${parcelId}`)).data
}

async function fetchWhatsappHistory(parcelId: number): Promise<{ history: ParcelStatus[] }> {
  return (await axios.get(`/xhr/notify/whatsapp-history?parcel_id=${parcelId}`)).data
}

// eslint-disable-next-line @stylistic/max-len
export async function fetchNotificationHistory(
  parcelId: number,
  betaFlag: boolean,
): Promise<Partial<TrackingMessageHistory>> {
  const emailHistory = await fetchEmailHistory(parcelId)
  const smsHistory = await fetchSmsHistory(parcelId)

  if (!betaFlag) {
    return {
      EMAIL: emailHistory || [],
      SMS: smsHistory || [],
    }
  } else {
    const whatsappHistory = await fetchWhatsappHistory(parcelId)
    return {
      EMAIL: emailHistory || [],
      SMS: smsHistory || [],
      WHATSAPP: whatsappHistory.history || { history: [] },
    }
  }
}
