import Segment from '@/common/utils/tracking/segment'

export default {
  /**
   * tracking call for navigation menu - top and settings menu
   */
  trackNavMenuItemClick(buttonId: string) {
    Segment.track('App - Selected Navigation Item', { menu_selected: buttonId })
  },
}
