import type { PrintSettings } from '@/features/printing/types/printing.types'
import type { ImporterFetchFail } from '@/types/services'
import type { PaymentWallType } from '@/features/payment-wall/types/payment-wall.types'
// more complex event payloads are defined here for readability of BusDefineEmits
import type {
  BusLabelsCreatedPayload,
  BusPackGoProcessData,
  BusFilterUpdatePayload,
  BusSubscriptionPlanModalData,
} from '@/common/types/event-bus.types'

export type BusDefineEmits = {
  /**
   * Events without payloads:
   */
  'app-modal:destroy': []
  'current-brand-loaded': []
  'incoming-orders-reload': []
  'IncomingOrders:clearSelection': []
  'initial-address-modal:dismiss': []
  'initial-address-modal:success': []
  'instagram:load-more': []
  'integration-changed': []
  'onboarding-wizard:save-step': []
  'open-quick-print-settings': []
  'orders-fetch-errors-parsed': []
  'orders-refresh-loaded': []
  'packgo-queue-reload': []
  'packgo:reset-filters': []
  'print-options-modal:cancel': []
  'shipment-announcement-failure': []
  'shipment-form:force-update': []
  'shipment-form:reset': []
  'shipment-form:validate-backend': []
  'shipment-form:validate': []
  'subscription-plan-toggle-billing-type': []
  'subscription-plan-toggle-edit-mode': []
  'validation-check-basic-display-instagram': []
  'validation-check': []
  'validation-reset': []
  /**
   * Events with payloads:
   */
  'app-modal:build': [newComponentName: string, componentProperties: Record<string, unknown> | undefined]
  // `preset` seems to be integration.settings.presets[number],
  // but currently we have no TS type for it (fix it, when you need it)
  'csv-preset-toggled': [preset: unknown]
  'instagram:change-orientation': [orientation: 'landscape' | 'portrait']
  'is-character-limit-exceeded': [value: boolean]
  'LabelPrintPipeline:labelsCreated': [payload: BusLabelsCreatedPayload]
  'orders-refresh-complete': [{ updatedAt: number }]
  'orders-refresh-fail': [reason: string]
  'orders-refresh-fetch-errors': [reason: ImporterFetchFail[] | string]
  'packgo:process': [processData?: BusPackGoProcessData | undefined]
  'parcel-monitor:update-filters': [filterData: BusFilterUpdatePayload]
  'payment-modal-completed': [invoiceId: string | undefined]
  'payment-modal-error': [invoiceId: string | undefined]
  'payment-wall-confirmed': [type: PaymentWallType]
  'payment-wall-dismissed': [type: PaymentWallType]
  'print-options-modal:error': [e: unknown]
  'print-options-modal:options': [printOptions: PrintSettings]
  'subscription-plan-change-shipments': [shipmentsCount: number]
  'subscription-plan-deactivate-modal': [planGroup: string]
  'subscription-plan-show-modal': [modalPlanData: BusSubscriptionPlanModalData]
  'update-selection-count': [count: number]
  'updating-order-quick-details': [isLoading: boolean]
}

type Cb<T extends keyof BusDefineEmits> = (...args: BusDefineEmits[T]) => void

type Options = { once?: boolean }

class Bus<EventName extends keyof BusDefineEmits> {
  /**
   * Map: { someEvent: Set([cb1, cb2]) }
   */
  eventListenersMap: Map<EventName, Set<Cb<EventName>>> = new Map()

  /**
   * If callback is in this map, then it should be called only once
   * Map: { someCallback: true }
   */
  onceMap: Map<Cb<EventName>, true> = new Map()

  updateOnceValue({
    callback,
    once,
    listenerAlreadyExists,
  }: {
    callback: () => void
    once: boolean
    listenerAlreadyExists: boolean
  }) {
    // give priority to non-once callbacks
    if (!once) {
      return this.onceMap.delete(callback)
    }

    // our new once === true, so
    // if listener exists, we do not need to change anything (if its once, its fine, if non-once - we give it priority)
    if (listenerAlreadyExists) {
      return
    }

    // only if it is a new callback add `once: true`
    return this.onceMap.set(callback, true)
  }

  registerEventListener<T extends EventName>(eventName: T, callback: Cb<T>, { once = false }: Options = {}) {
    const listenersSet: Set<Cb<T>> = this.eventListenersMap.get(eventName) || new Set()

    const listenerAlreadyExists = listenersSet.has(callback)

    this.updateOnceValue({ callback, once, listenerAlreadyExists })

    if (listenerAlreadyExists) {
      return
    }
    listenersSet.add(callback)
    this.eventListenersMap.set(eventName, listenersSet as Set<Cb<EventName>>)
  }

  $on<T extends EventName>(eventName: T, callback: Cb<T>, options?: Options) {
    this.registerEventListener(eventName, callback, options)
  }

  $once<T extends EventName>(eventName: T, callback: Cb<T>) {
    this.registerEventListener(eventName, callback, { once: true })
  }

  /**
   * Removes the event listener for the given event name
   */
  $off<T extends EventName>(eventName: T, callback: Cb<T>) {
    const listenersSet = this.eventListenersMap.get(eventName)

    if (!listenersSet?.size) {
      return
    }

    return listenersSet.delete(callback as Cb<EventName>)
  }

  /**
   * Removes all event listeners for the given event
   */
  $removeAllListeners<T extends EventName>(eventName: T) {
    this.eventListenersMap.delete(eventName)
  }

  /**
   * See: https://v2.vuejs.org/v2/api/#vm-emit
   */
  $emit<T extends EventName>(eventName: T, ...args: Parameters<Cb<T>>) {
    const listenersSet = this.eventListenersMap.get(eventName)
    if (!listenersSet?.size) {
      return
    }
    listenersSet.forEach((cb) => {
      cb(...args)
      const isOnce = this.onceMap.get(cb)
      if (isOnce) {
        this.onceMap.delete(cb)
        listenersSet.delete(cb)
      }
    })
  }

  $reset() {
    this.eventListenersMap = new Map()
  }
}

const EventBus = new Bus()

export default EventBus
