import Segment from '@/common/utils/tracking/segment'

export default {
  userJoinedExperiment(experimentID: string, variantID: string) {
    Segment.track('Experiment - experiment active', {
      experiment_id: experimentID,
      variant_id: variantID,
    })
  },
}
