import type { UserLocale, UserCurrency } from '@/types/models'
import type { LocaleBundle } from '@/application/types/i18n.types'

import { createI18n, type IntlNumberFormat } from 'vue-i18n'

import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar.js'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

import { localeBundle as defaultLocaleBundle } from '@/application/i18n/bundles/bundle-en-US'
import {
  AVAILABLE_LANGUAGES,
  DEFAULT_CURRENCY,
  DEFAULT_LOCALE,
  DEFAULT_TRACKING_MESSAGE_LOCALE,
} from '@/application/i18n/constants'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(calendar)

function getNumberFormat(currency: UserCurrency): IntlNumberFormat {
  return {
    number: {
      minimumFractionDigits: 2,
    },
    currency: {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    },
    // Note to those who seek to enable unit formatting:
    // We need to avoid using `style: 'unit'`.
    // unit: {
    //   style: 'unit',
    //   unit: 'kilogram',
    // },
  }
}

/**
 * Read more in the [vue-i18n documentation][1].
 *
 * [1]: https://kazupon.github.io/vue-i18n/api/#constructor-options
 */
export const i18n = createI18n({
  allowComposition: true,
  globalInjection: true,
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  formatFallbackMessages: true,
  messages: {
    [DEFAULT_LOCALE]: defaultLocaleBundle.messages,
  },
  numberFormats: {
    [DEFAULT_LOCALE]: getNumberFormat(DEFAULT_CURRENCY),
  },
  warnHtmlInMessage: 'off',
})

/**
 * Any loaded bundles will be stored in a `Map` object.
 * When loading a locale, we check if it has an entry in this map first.
 * This way, an already loaded locale bundle won’t be loaded over the network again.
 */
const loadedBundles: Map<UserLocale, LocaleBundle> = new Map()
loadedBundles.set(DEFAULT_LOCALE, defaultLocaleBundle)

/**
 * Loads a locale’s translation files asynchronously.
 *
 */
export async function loadLocaleAsync(
  userLanguage: UserLocale = DEFAULT_LOCALE,
  currency: UserCurrency = DEFAULT_CURRENCY,
): Promise<UserLocale> {
  const locale = normalizeLocale(userLanguage)

  if (!Object.keys(AVAILABLE_LANGUAGES).includes(locale)) {
    // eslint-disable-next-line no-console
    console.warn(`The locale ${locale} is not available. Falling back to ${DEFAULT_LOCALE}.`)

    return setLocale(DEFAULT_LOCALE, defaultLocaleBundle, currency)
  }

  const loadedLocaleBundle = loadedBundles.get(locale)
  if (loadedLocaleBundle !== undefined) {
    return setLocale(locale, loadedLocaleBundle, currency)
  }

  const localModule: { localeBundle: LocaleBundle } = await import(`@/application/i18n/bundles/bundle-${locale}.ts`)
  return setLocale(locale, localModule.localeBundle, currency)
}

function normalizeLocale(locale: UserLocale): UserLocale {
  const [language, region] = locale.split('-')

  return (`${language}-${region.toUpperCase()}`) as UserLocale
}

async function setLocale(locale: UserLocale, localeBundle: LocaleBundle, currency: UserCurrency) {
  loadedBundles.set(locale, localeBundle)

  document.documentElement.lang = locale

  i18n.global.locale = locale
  i18n.global.setLocaleMessage<typeof localeBundle.messages>(locale, localeBundle.messages)
  i18n.global.setNumberFormat(locale, getNumberFormat(currency))

  dayjs.locale(localeBundle.dayJsLocale)

  return locale
}

/**
 * Adds the specified locale messages to the library
 * This way we can localise translations if needed
 */
export async function setMessages(countryIso: string) {
  const locale = DEFAULT_TRACKING_MESSAGE_LOCALE[countryIso]

  if (locale) {
    const localModule: { localeBundle: LocaleBundle } = await import(`@/application/i18n/bundles/bundle-${locale}.ts`)
    i18n.global.setLocaleMessage<typeof localModule.localeBundle.messages>(locale, localModule.localeBundle.messages)
  }
}

export default i18n.global
