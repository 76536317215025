import store from '@/common/stores/store'
import { SETTINGS_RETURN_RULES_LIST_ROUTE } from '@/features/return-rules/routes'

export function userHasReturnRulesAccess() {
  // is staff account OR the return_rules feature should be enabled
  return store.getters.user?.is_staff || store.getters.user?.features?.return_rules
}

/**
 * @param {import('node_modules/vue-router/types/index').Route} _to
 * @param {import('node_modules/vue-router/types/index').Route} _from
 * @param {import('node_modules/vue-router/types/index').NavigationGuardNext} next
 */
export function returnRuleDetailsGuard(_to, _from, next) {
  if (!userHasReturnRulesAccess()) {
    next({ name: SETTINGS_RETURN_RULES_LIST_ROUTE })
  } else {
    next()
  }
}
