import { isAxiosError } from 'axios'

/**
 * Decide which errors should be reported to Sentry. Try to ignore unactionable errors (e.g. 403 status code).
 */
export function shouldReportError(error: unknown): boolean {
  // Ignore unactionable API response codes:
  const errorCodesToIgnore = [401, 403, 404, 429]
  if (isAxiosError(error) && error.response?.status && errorCodesToIgnore.includes(error.response?.status)) {
    return false
  }

  return true
}
