import store from '@/common/stores/store'
import { ONBOARDING_WIZARD_ROUTE } from '@/features/onboarding-wizard/routes.js'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'

/**
 * Check if the user has access to the Onboarding Wizard
 * @param {import('vue-router').RouteLocationNormalized} to
 * @param {import('vue-router').RouteLocationNormalized} _from
 * @param {import('vue-router').NavigationGuardNext} next
 */
export const redirectAwayOrToOnboardingWizard = (to, _from, next) => {
  if (!store.getters.onboardingWizardSkipped && store.getters.showOnboardingWizard && !to.path.includes('onboarding')) {
    return next({ name: ONBOARDING_WIZARD_ROUTE })
  } else if (!store.getters.showOnboardingWizard && to.path.includes('onboarding')) {
    return next({ name: SHIPPING_INCOMING_ORDERS_ROUTE })
  } else {
    return next()
  }
}
