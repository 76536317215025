/** @typedef {typeof state} ModuleState */
/** @typedef {import('vuex').ActionContext} ActionContext */
/** @typedef {import('vuex').Module} StoreModule */
/** @typedef {import('vuex').Payload} Payload */

/** @typedef {import('@/features/onboarding-wizard/types/onboarding-wizard.types').Step} Step */
/** @typedef {import('@/features/onboarding-wizard/types/onboarding-wizard.types').updateSteps} updateSteps */
/** @typedef {import('@/features/onboarding-wizard/types/onboarding-wizard.types').Status} Status */
/** @typedef {import('@/features/onboarding-wizard/types/onboarding-wizard.types').Id} Id */

import { logError } from '@/common/utils/errors/error-handlers'
import {
  ONBOARDING_WIZARD_FETCH_STEPS,
  ONBOARDING_WIZARD_FETCH_STEP,
  ONBOARDING_WIZARD_FETCH_STATUS,
  ONBOARDING_WIZARD_COMPLETE_STEP,
  ONBOARDING_WIZARD_SKIP,
  ONBOARDING_WIZARD_SAVE_YOUR_DETAILS,
} from '@/features/onboarding-wizard/stores/action.types.js'

import {
  ONBOARDING_WIZARD_SET_STEP,
  ONBOARDING_WIZARD_SET_STEPS,
  ONBOARDING_WIZARD_SET_STATUS,
  ONBOARDING_WIZARD_SET_EXPERIMENT,
  ONBOARDING_WIZARD_SET_INTEGRATION_FORM,
} from '@/features/onboarding-wizard/stores/mutation.types.js'

import OnboardingWizardService from '@/features/onboarding-wizard/api/onboarding-wizard.api'

// initial state
const state = {
  steps: [],
  step: {},
  status: {
    is_wizard_skipped: true,
    /**
     * Is true for users who have completed the wizard and for those who are not eligible for it
     */
    is_wizard_complete: true,
  },
  userSteps: {
    integration: {
      id: null,
      systemCode: null,
    },
  },
  experiments: {},
}

// getters
const getters = {
  getExperimentsOW: (/** @type {ModuleState} */ state) => {
    return state.experiments
  },
  getPrimaryStepsOnboardingWizard: (/** @type {ModuleState} */ state) => {
    return state.steps.filter(step => step.type === 'primary')
  },

  getSecondaryStepsOnboardingWizard: (/** @type {ModuleState} */ state) => {
    return state.steps.filter(step => step.type === 'secondary')
  },

  getActiveStepOnboardingWizard: (/** @type {ModuleState} */ state) => state.step,

  onboardingWizardSkipped: (/** @type {ModuleState} */ state) => state.status.is_wizard_skipped,

  showOnboardingWizard: (/** @type {ModuleState} */ state) => !state.status.is_wizard_complete,

  onboardingWizardIntegration: (/** @type {ModuleState} */ state) => state.userSteps.integration,

  /**
   * Counts the number of steps and adds one static step
   * @returns {number}
   */
  onboardingWizardStepsTotal: (/** @type {ModuleState} */ state) =>
    state.steps.filter(step => step.type === 'primary').length + 1,

  /**
   * Counts the number of completed steps and adds one static step that is completed by default
   * @returns {number}
   */
  onboardingWizardStepsCompleted: (/** @type {ModuleState} */ state) => {
    let completedCount = 1
    let primarySteps = state.steps.filter(step => step.type === 'primary')
    primarySteps.map((step) => {
      if (step.is_complete === true) {
        completedCount++
      }
    })
    return completedCount
  },

  /**
   * Defines a mandatory step and checks whether it has not been completed yet
   * @returns {boolean}
   */
  onboardingWizardMandatoryStepUncompleted: (/** @type {ModuleState} */ state) => {
    const mandatoryStep = state.steps.find(step => step.id === 'your-details')
    return mandatoryStep.is_complete === false
  },

  userIsInPhoneSplitExperimentGroup: (/** @type {ModuleState} */ state) => {
    const phoneStep = state.steps.find(step => step.id === 'your-telephone')
    return typeof phoneStep !== 'undefined'
  },
}

// actions
const actions = {
  /**
   * @param {ActionContext} context
   */
  async [ONBOARDING_WIZARD_FETCH_STEPS]({ commit }) {
    try {
      const { steps } = await OnboardingWizardService.fetchAllSteps()
      commit(ONBOARDING_WIZARD_SET_STEPS, steps)
    } catch {
      // continue to use cached steps
    }
  },
  /**
   * @param {ActionContext} context
   * @param {Id} Id
   */
  async [ONBOARDING_WIZARD_FETCH_STEP]({ commit }, Id) {
    const step = await OnboardingWizardService.fetchStep(Id)
    commit(ONBOARDING_WIZARD_SET_STEP, step)
  },
  /**
   * @param {ActionContext} context
   */
  async [ONBOARDING_WIZARD_FETCH_STATUS]({ commit }) {
    try {
      const { status } = await OnboardingWizardService.fetchStatus()
      commit(ONBOARDING_WIZARD_SET_STATUS, status)
    } catch {
      // if the service returns an error, don't show the wizard
      commit(ONBOARDING_WIZARD_SET_STATUS, {
        is_wizard_complete: true,
      })
    }
  },
  /**
   * @param {ActionContext} context
   * @param {string} id
   */
  // eslint-disable-next-line no-empty-pattern
  async [ONBOARDING_WIZARD_COMPLETE_STEP]({ _commit, state }, id) {
    const currentStep = state.steps.find(step => step.id === id)
    if (currentStep?.is_complete === false) {
      try {
        await OnboardingWizardService.completeStep(id)
      } catch (error) {
        logError(error)
      }
    }
  },
  /**
   * @param {ActionContext} context
   */
  async [ONBOARDING_WIZARD_SKIP]({ dispatch, getters }) {
    if (!getters.onboardingWizardSkipped) {
      try {
        await OnboardingWizardService.skipWizard()
        await dispatch(ONBOARDING_WIZARD_FETCH_STATUS)
      } catch {
        // Allow to proceed to the App even if there is a problem here
      }
    }
  },

  /**
   * Save Your Details form data. If the response is successful, complete the 'your-details' step.
   * @param {ActionContext} context
   * @param {Object} formData form data to be saved.
   */
  async [ONBOARDING_WIZARD_SAVE_YOUR_DETAILS]({ dispatch }, formData) {
    await OnboardingWizardService.saveYourDetails(formData)
    dispatch(ONBOARDING_WIZARD_COMPLETE_STEP, 'your-details')
  },
}

// mutations
const mutations = {
  /**
   * @param {ModuleState} state
   * @param {Object} variantExperiment
   */
  [ONBOARDING_WIZARD_SET_EXPERIMENT](state, variantExperiment) {
    if (variantExperiment?.name) {
      state.experiments[variantExperiment?.name] = variantExperiment?.value
    }
  },
  /**
   * @param {ModuleState} state
   * @param {updateSteps} stepsUpdate
   */
  [ONBOARDING_WIZARD_SET_STEPS](state, stepsUpdate) {
    state.steps = stepsUpdate
  },
  /**
   * @param {ModuleState} state
   * @param {Step} stepUpdate
   */
  [ONBOARDING_WIZARD_SET_STEP](state, stepUpdate) {
    state.step = stepUpdate
  },
  /**
   * @param {ModuleState} state
   * @param {Status} status
   */
  [ONBOARDING_WIZARD_SET_STATUS](state, status) {
    state.status = {
      is_wizard_skipped: status.is_wizard_skipped ?? true,
      is_wizard_complete: status.is_wizard_complete ?? false,
    }
  },

  /**
   * Set integration systemCode and Id.
   * @param {ModuleState} state
   * @param {Object} integration
   */
  [ONBOARDING_WIZARD_SET_INTEGRATION_FORM](state, integration) {
    state.userSteps.integration = integration
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
