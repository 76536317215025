import type { SnakeCase, CamelCase } from '../types/change-case.types'

export const toSnakeCase = <T extends string>(s: T): SnakeCase<T> =>
  s.replace(/[A-Z]/g, m => `_${m.toLowerCase()}`) as SnakeCase<T>

export const toCamelCase = <T extends string>(str: T): CamelCase<T> =>
  str.replace(
    /(?!^)_(.)/g,
    // we want to call stuff on any, because we know we have a group in regex
    // eslint-disable-next-line @stylistic/max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    (_, char) => char.toUpperCase(),
  ) as CamelCase<T>
