import type { UserCurrency, UserLocale } from '@/types/models'

/**
 * Languages codes should follow [RFC 5646][ref:spec].
 *
 * The grammar is as follows:
 *
 * - two lowercase letters indicating the language ([section in spec][ref:language])
 * - a dash character
 * - two uppercase letters indicating the region ([section in spec][ref:region])
 *
 * [ref:spec]: https://tools.ietf.org/html/rfc5646
 * [ref:language]: https://tools.ietf.org/html/rfc5646#page-9
 * [ref:region]: https://tools.ietf.org/html/rfc5646#page-13
 *
 */
export const LOCALE: Record<string, UserLocale> = {
  GERMAN_DE: 'de-DE',
  ENGLISH_UK: 'en-GB',
  ENGLISH_US: 'en-US',
  SPANISH_ES: 'es-ES',
  FRENCH_FR: 'fr-FR',
  ITALIAN_IT: 'it-IT',
  DUTCH_NL: 'nl-NL',
}

/**
 * Used in Tracking messages to get the correct locale when a country is chosen
 *
 */
export const DEFAULT_TRACKING_MESSAGE_LOCALE: Record<string, UserLocale> = {
  AT: 'de-DE',
  DE: 'de-DE',
  UK: 'en-GB',
  GB: 'en-GB',
  US: 'en-US',
  ES: 'es-ES',
  FR: 'fr-FR',
  IT: 'it-IT',
  BE: 'nl-NL',
  NL: 'nl-NL',
  ZZ: 'en-GB',
}

export const DEFAULT_LOCALE: UserLocale = (import.meta.env.VUE_APP_I18N_LOCALE) || LOCALE.ENGLISH_US

export const DEFAULT_CURRENCY: UserCurrency = 'EUR'

// eslint-disable-next-line no-unused-vars
export const AVAILABLE_LANGUAGES: { [K in UserLocale]?: string } = {
  [LOCALE.GERMAN_DE]: 'Deutsch',
  [LOCALE.ENGLISH_UK]: 'English (UK)',
  [LOCALE.ENGLISH_US]: 'English (US)',
  [LOCALE.SPANISH_ES]: 'Español',
  [LOCALE.FRENCH_FR]: 'Français',
  [LOCALE.ITALIAN_IT]: 'Italiano',
  [LOCALE.DUTCH_NL]: 'Nederlands',
}
