import { CONTRACTS_FETCH_CONTRACTS, CONTRACTS_FETCH_CONTRACT, CONTRACTS_FETCH_CONTRACTS_V2 } from './action.types.js'
import {
  CONTRACTS_SET_CONTRACT_DETAILS,
  CONTRACTS_SET_CONTRACTS,
  CONTRACTS_SET_CONTRACTS_V2,
} from './mutation.types.js'

import ContractApi from '@/features/carriers/api/contract.api'

const initialState = {
  contracts: [],
  contractsV2: [],
  contract: null,
}

const getters = {
  contracts: state => state.contracts,
  contractsV2: state => state.contractsV2,
  contract: state => state.contract,
  /** @type {(state: ModuleState) => Set<string>} */
  carriersWithActiveContracts: (state) => {
    return new Set(state.contracts.filter(contract => contract.is_active).map(contract => contract.carrier))
  },
}

const actions = {
  async [CONTRACTS_FETCH_CONTRACTS]({ commit, getters }) {
    const response = await ContractApi.findAll()

    const contracts = response.data

    const sortedContracts = contracts.sort((contractA, contractB) => {
      const carrierNameA = getters.carrierById(contractA.carrier).name
      const carrierNameB = getters.carrierById(contractB.carrier).name

      let sortResult = carrierNameA.localeCompare(carrierNameB)

      if (!sortResult) {
        sortResult = contractA.client_id.localeCompare(contractB.client_id)
      }

      return sortResult
    })

    commit(CONTRACTS_SET_CONTRACTS, sortedContracts)
  },

  async [CONTRACTS_FETCH_CONTRACTS_V2]({ commit }, onlyActive = true) {
    const contracts = await ContractApi.fetchAll_v2(onlyActive)

    contracts.sort(
      (contractA, contractB) =>
        contractB.is_default - contractA.is_default ||
        Boolean(contractB.client_id) - Boolean(contractA.client_id) ||
        contractA.name - contractB.name,
    )

    commit(CONTRACTS_SET_CONTRACTS_V2, contracts)
  },

  async [CONTRACTS_FETCH_CONTRACT]({ commit }, contractId) {
    const response = await ContractApi.find(contractId)
    commit(CONTRACTS_SET_CONTRACT_DETAILS, response.data)
  },
}

const mutations = {
  [CONTRACTS_SET_CONTRACTS](state, contracts) {
    state.contracts = contracts
  },

  [CONTRACTS_SET_CONTRACTS_V2](state, contracts) {
    state.contractsV2 = contracts
  },

  [CONTRACTS_SET_CONTRACT_DETAILS](state, contract) {
    state.contract = contract
  },
}

export default {
  state: initialState,
  getters,
  mutations,
  actions,
}
